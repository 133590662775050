import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import VolumeInput from 'SHARED/components/VolumeInput';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import Preloader from 'SHARED/components/ThePreloader';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import texts from 'SHARED/helpers/texts';
import AcceptOfferLivePreview from './AcceptOfferLivePreview';

export type Accept = (form?: { acceptVolume: string } | null, bidId?: number | null) => void;

interface IOriginalOfferSectionProps {
  acceptOffer: Accept,
  volume: number,
  createdAt: number | null,
  splittable: boolean,
}

const OriginalOfferSection: React.FC<IOriginalOfferSectionProps> = ({ volume, createdAt, acceptOffer, splittable }) => {
  const methods = useFormContext();
  const { setValue, watch } = methods;
  const { ON_SPEC: { type, priceDetails }, pricesLoading } = useTypedSelector((state) => state.offer);
  const { userTimezone, userTimezoneAbbr } = useUserTimezone();

  const isTobeProduced = type?.value === 'TO_BE_PRODUCED';
  const isProposal = type?.value === 'RFP_PROPOSAL';

  const netPrice = priceDetails?.netPrice || 0;
  const acceptVolumeField = watch('form.acceptVolume', null);

  const canAcceptOffer = !!volume && volume > 0;

  const detailsVolume = (splittable && canAcceptOffer) ? acceptVolumeField : volume;
  const offerTypeName = isProposal ? 'Proposal' : 'Offer';

  // if buyer accepted some volume
  // remaining volume should be set to appropriate field
  useEffect(() => {
    setValue('form.acceptVolume', volume);
  }, [volume]);

  return (
    <>
      <div
        className="offer-auction-details prebid-info-section"
        data-testing="original-offer-section"
      >
        <Preloader isLoading={pricesLoading} options="isLocal" />

        <div>
          <strong>
            {`${offerTypeName} placed on ${dateWithTimezone(createdAt, userTimezone)} ${userTimezoneAbbr}`}
          </strong>
        </div>
        <div>

          {splittable && canAcceptOffer && (
            <VolumeInput
              label="Offer volume"
              name="form.acceptVolume"
              value={volume}
              disabled={!isTobeProduced && !splittable}
              hasMaxValue
              testingName="accept-volume-input"
              placeholder={volume.toString()}
              tooltipText={texts.tooltips.buyer_accept_offer_volume}
            />
          )}

          <AcceptOfferLivePreview
            volume={detailsVolume}
            price={netPrice}
          />

          <div>
            <br />
            {canAcceptOffer && (
              <button
                type="button"
                className="btn-primary"
                onClick={() => { acceptOffer(); }}
                data-testing="accept-offer-button"
              >
                Accept this {offerTypeName.toLowerCase()}
              </button>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default OriginalOfferSection;
