import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

export const OfferDealLogisticsSimple = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    departureFrom,
    departureTo,
    incoterms,
    logisticLocationNote,
    type,
  } = ON_SPEC;

  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';

  if (!id || !isSimpleOffer) return null;

  return (
    <>
      <DataItemPreview
        title="Departure"
        datesRange={[departureFrom, departureTo]}
      />

      <DataItemPreview
        title="Seller incoterms"
        item={incoterms}
      />

      <DataItemPreview
        title="Seller logistics location"
        item={logisticLocationNote}
      />
    </>
  );
};
