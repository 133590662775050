import { axios_Core } from 'SHARED/helpers/axios';
import notification, { showNotification } from 'SHARED/helpers/notifications';

interface IDeleteBidArgs {
  offerId: number | string
  type: 'OFF_SPEC' | 'ON_SPEC'
  bidId?: number | string
}

export const deleteBid = async (args: IDeleteBidArgs) => {
  const { offerId, type, bidId } = args;
  const isOffer = type === 'ON_SPEC';

  try {
    if (isOffer) {
      await axios_Core.delete(`/v1/negotiation/bid?id=${bidId}`);
    } else {
      await axios_Core.delete(`/v1/offers/off-spec/${offerId}/bids`);
    }

    showNotification('BID_DELETED');

    return { isSuccess: true };
  } catch (error) {
    console.log('deleteBid error', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not delete bid...',
    });

    return { isSuccess: false };
  }
};
