import React, { useState } from 'react';
import Preloader from 'SHARED/components/ThePreloader';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import BidHistoryModal from 'SHARED/modals/BidHistoryModal';
import { OfferBid } from 'SHARED/types/bidTypes';
import { getBidHistory } from 'SHARED/api/offers/get/getBidHistory';

const OfferBottom: React.FC = () => {
  const { ON_SPEC: { id, parentOffer } } = useTypedSelector((state) => state.offer);
  const { me } = useTypedSelector((state) => state.users);

  const [history, setHistory] = useState<OfferBid[] | null>(null);
  const [loading, setLoading] = useState(false);

  const isRfp = parentOffer?.type?.value === 'RFP';

  const viewBidHistory = (buyerId: number) => {
    setLoading(true);
    (async () => {
      const result = await getBidHistory({ offerId: id, buyerId });
      setLoading(false);
      setHistory(result.data);
    })();
  };

  if (isRfp) {
    return null;
  }

  return (
    <>
      <Preloader isLoading={loading} />

      <br />

      <button
        type="button"
        className="link-button"
        onClick={() => viewBidHistory(me.id || 0)}
      >
        View negotiation history
      </button>

      {history && (
        <BidHistoryModal
          handleClose={setHistory}
          history={history}
          buyer={me.organization?.orgName || ''}
        />
      )}

      <br />
      <br />

    </>
  );
};

export default OfferBottom;
