import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import useCalculateOfferPrices from 'hooks/useCalculateOfferPrices';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import TableSpacer from 'SHARED/components/TableSpacer';
import PriceTableRow, { ITableRow } from './PriceTableRow';

interface IProps {
  volume: number;
  price: number;
}

const AcceptOfferLivePreview: FC<IProps> = ({
  volume,
  price,
}) => {
  const { ON_SPEC: { type, priceDetails } } = useTypedSelector((state) => state.offer);
  const { watch } = useFormContext();

  // flags
  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags === end

  const {
    netPrice,
    packagingPrice,
    logisticPrice,
    selectedLogisticsType,
    creditPrice,
    totalPrice,
  } = useCalculateOfferPrices({ type: 'offer', volume, price, watch });
  // NEW === END

  const packageRow = { value: packagingPrice, label: 'Packaging differential' };

  const pricesRows: ITableRow[] = [
    { value: netPrice, label: 'Net Price' },
    { value: totalPrice, label: 'Total price', bold: true },
  ];

  const vasRows: ITableRow[] = [
    {
      value: logisticPrice,
      label: 'Logistic',
      byInfo: priceDetails?.logisticProvider?.value,
      icon: selectedLogisticsType,
    },
    {
      value: creditPrice,
      label: 'Financing',
      byInfo: priceDetails?.financingProvider?.label,
    },
  ];

  if (!isSimpleOffer) {
    pricesRows.splice(1, 0, ...vasRows);
  }

  if (isToBeProduced) {
    pricesRows.splice(1, 0, packageRow);
  }

  return (
    <>
      <table className="bid-table bid-table--preview">
        <tbody>
          <tr>
            <td>Volume: </td>
            <td>{volume ? `${volume} mt` : '-'}</td>
          </tr>

          <TableSpacer />

          {pricesRows.map((p) => (
            <PriceTableRow
              {...p}
              currency={priceDetails?.currency}
              key={p.label}
            />
          ))}

        </tbody>
      </table>

      <OfferDisclaimer userType="buyer" />
    </>
  );
};

export default AcceptOfferLivePreview;
