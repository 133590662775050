import React, { useState } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import FormattedPrice from 'SHARED/components/Price';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormSelect from 'SHARED/components/Select';
import { handleDate, handleDateRange, MpcDocLink } from 'SHARED/helpers/common';
import './Partials/bid-section.scss';

const TobeProducedFields: React.FC = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    matchedProducts,
    description,
    productType,
    productCategory,
    documentCapabilities,
    packages,
    coaUsed,
    customLabelPossible,
    productionLocation,
    salesTerms,
    price,
    currency,
    remainingVolume,
    totalVolume,
    documents,
    splittable,
    departureFrom,
    departureTo,
    departurePeriod,
    endDate,
    ownerOrg,
    productCharacteristics,
    loadOptions,
    isApproved,
    type,
    parentOffer,
  } = ON_SPEC;

  const isForRfp = parentOffer?.type.value === 'RFP';

  const openSpecsPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsPopup(true);
  };

  const [isPopup, setIsPopup] = useState(false);

  const departure = departurePeriod.value === 'MONTH'
    ? handleDate({ date: departureFrom, format: 'MMM YYYY' })
    : handleDateRange({ from: departureFrom, to: departureTo });

  return (
    <>

      <div className="page-column">
        <div className="page-section-title">Product</div>
        {description && <DataItemPreview multiline multilineText={description} title="REMARKS FOR THE BUYER" />}
        <DataItemPreview item={type?.label} title="OFFER TYPE" />
        <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
        <DataItemPreview item={productType} title="PRODUCT TYPE" />

        <div className="data-preview-row">
          <div className="label-text">SELLER PRODUCT SPECIFICATION</div>
          <div><a href="##" onClick={(e) => openSpecsPopup(e)}>Specifications details</a></div>
        </div>

        <DataItemPreview item={documentCapabilities} title="DOCUMENTS INCLUDED" />
        <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />

        {packages && (
          <FormSelect
            label="PACKAGING"
            name="form.packaging"
            options={packages}
            selected={packages[0]}
          />
        )}

        <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />
        <DataItemPreview item={salesTerms} title="SALES TERMS" />

        {salesTerms?.value === 'MPC' && (
          <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
            <i className="icon-flag" />
            <span>MPC sales terms</span>
          </a>
        )}

        {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}

        <>
          <div className="page-section-title">Match</div>
          <DataItemPreview item="Product Specification of the seller" icon="icon-matched" title="Matched on" />
          <div className="label-text">Our matched Product Specification</div>

          {matchedProducts?.map((p) => (p.label)).join(', ')}
          {matchedProducts && matchedProducts.length === 0 && <div>This offer does not match any of our product specifications.</div>}
        </>

      </div>

      <div className="page-column">
        <div className="page-section-title">Seller</div>
        <DataItemPreview icon={isApproved ? 'icon-approved' : ''} item={ownerOrg.orgName} title="SELLER COMPANY" />
        <DataItemPreview item={ownerOrg.country} title="COUNTRY" />
        <div className="page-section-title">Shipping</div>
        <DataItemPreview item={departure} title="DEPARTURE" />
        <div className="page-section-title">Volume and price</div>
        <DataItemPreview item={isForRfp ? totalVolume : remainingVolume} title="VOLUME" suffix="mt" />
        <DataItemPreview icon={splittable ? 'icon-splittable' : ''} item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />
        <FormattedPrice value={price} title="PRICE" prefix={currency} suffix="/mt" />
        <div className="page-section-title">Offer expiration</div>
        <DataItemPreview item={endDate} isDate title="Offer end date" />
        <div className="page-section-title">Loading details options</div>
        <DataItemPreview item={loadOptions} title="Buyer loading option" />

      </div>

      {isPopup && productCharacteristics && (
        <ProductSpecsDetailsModal
          handleClose={setIsPopup}
          characteristics={productCharacteristics}
          coaUsed={coaUsed}
        />
      )}

    </>
  );
};

export default TobeProducedFields;
