import React, { FC } from 'react';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { OfferBid } from 'SHARED/types/bidTypes';
import TableSpacer from 'SHARED/components/TableSpacer';
import PriceTableRow, { ITableRow } from './PriceTableRow';

interface IProps {
  bid: OfferBid
  onDeleteBid: (arg: any) => any
  currency: string
  isSimpleOffer?: boolean
}

const LatestBidSection: FC<IProps> = ({
  bid,
  onDeleteBid,
  currency,
  isSimpleOffer = false,
}) => {
  const { userTimezone, userTimezoneAbbr } = useUserTimezone();

  let vasRows: ITableRow[] = [];

  if (!isSimpleOffer) {
    vasRows = [
      { value: bid.logisticPrice || 0, label: 'Logistic', byInfo: bid.logisticHandler?.label },
      { value: bid.creditPrice || 0, label: 'Financing', byInfo: bid.financingProvider?.label },
    ];
  }

  const prices: ITableRow[] = [
    { value: bid.netPrice || 0, label: 'Price' },
    ...vasRows,
    { value: bid.totalPrice || 0, label: 'Total', bold: true },
  ];

  return (
    <div
      className="offer-auction-details bid-section"
      data-testing="latest-bid-section"
    >
      <div className="page-section-title">Our latest bid</div>

      <p>
        {`${dateWithTimezone(bid?.created, userTimezone)} ${userTimezoneAbbr} by ${bid.buyerName}`}
      </p>

      <table className="bid-table bid-table--preview">
        <tbody>
          <tr>
            <td>Volume:</td>
            <td>{bid.volume} mt</td>
          </tr>

          <TableSpacer />

          {prices.map((p) => (
            <PriceTableRow {...p} currency={currency} key={p.label} />
          ))}

          <PriceTableRow label="Message" dataString={bid?.message || null} />
        </tbody>
      </table>

      {bid.deletable && (
        <button
          type="button"
          className="btn-icon"
          onClick={(e) => onDeleteBid(e)}
          data-testing="delete-bid-button"
        >
          <i className="icon-delete" />
        </button>
      )}
    </div>
  );
};

export default LatestBidSection;
