import React from 'react';

const OfferRequestDocument = () => {
  console.log('OfferRequestDocument');

  return (
    <>
      <h1>Request a document FORM</h1>
    </>
  );
};

export default OfferRequestDocument;
