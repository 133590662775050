import React from 'react';
import { Link } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const DealsList = () => {
  const { ON_SPEC: { hasDeals, deals, parentOffer } } = useTypedSelector((state) => state.offer);

  const isRfp = parentOffer?.type?.value === 'RFP';

  return (
    <div>
      {hasDeals
        && (
          <>
            {deals?.map((deal) => (
              <div key={deal.id}>
                <Link
                  type="button"
                  to={`/${isRfp ? 'rfp' : 'onspec'}/deals/${deal.id}`}
                >
                  {`Show deal (${deal.number}) details`}
                </Link>
              </div>
            ))}
            <br />
            <br />
          </>
        )}
    </div>
  );
};

export default DealsList;
