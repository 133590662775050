import React, { useEffect, useState } from 'react';
import Select from 'SHARED/components/Select';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { offerTexts } from 'SHARED/helpers/texts';
import { useFormContext } from 'react-hook-form';
import { sortBy } from 'lodash';
import validationRules from 'SHARED/helpers/validation';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { DestinationLocation, Dictionary, Price } from 'SHARED/types/offerTypes';
import clsx from 'clsx';
import PriceOption from '../PriceOption';
import NoLogisticsOptionsMsg from '../messages/NoLogisticsOptionsMsg';
import RFPDeliveryVas from './RFPDeliveryVas';

const OWN_LOGISTIC: Price = {
  price: 0,
  partner: { label: 'I’ll arrange logistics myself', value: 'OWN' },
  priceId: 'OWN',
  logisticLocationId: 0,
  transportPriceEur: 0,
  transportPriceUsd: 0,
  caption: 'seller incoterms apply',
};
const BUYER_INCOTERMS = 'form.buyerIncoterms';
const LOCATION_NAME = 'form.logisticLocationId';
const PRICE_NAME = 'form.logisticPriceId';

const convertToDictionary = (value: DestinationLocation): Dictionary => ({
  label: value.address,
  value: value.address.replace(' ', '_'),
  main: value.main,
  payload: value,
});

const prepareLocationOptions = (locations: DestinationLocation[]): Dictionary[] => (
  sortBy(locations.map((location) => (convertToDictionary(location))), (i) => !i.main)
);

interface Props {
  updatePrices: (isIncludeVas: boolean) => void
}

const OfferLogistic: React.FC<Props> = ({ updatePrices }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const { vasLogistics, currency, agreedOnDelivery, type } = ON_SPEC;
  const isProposal = type?.value === 'RFP_PROPOSAL';
  const isShouldHideLogistics = isProposal && agreedOnDelivery;
  // const isShouldHideFinancing = isProposal && agreedOnPayment;

  const { watch, setValue } = useFormContext();
  const choosedDestinationLocation: Dictionary = watch(LOCATION_NAME);
  const destinationsLength = (vasLogistics ? vasLogistics.destinationLocations.length : 0);
  const [logisticOptions, setLogisticOptions] = useState<Price[]>([]);

  const setDefaultDestination = () => {
    if (vasLogistics && vasLogistics?.destinationLocations.length > 0) {
      const firstLocation = vasLogistics.destinationLocations[0];
      const mainLocation = vasLogistics.destinationLocations.find((location) => location.main);
      // according to the business logic we should set default location to main location
      // if it exists, otherwise to first location will do
      const defaultLocation = convertToDictionary(mainLocation || firstLocation);
      setValue(LOCATION_NAME, defaultLocation);
    }
  };

  const updateLogisticOptions = () => {
    // TODO: CHECK - so it should set prices only for first time (see useEffect for vasLogistics before)
    // in our case only after vasLogistics has been updated and prices option updated and cleared
    setLogisticOptions([...vasLogistics?.destinationLocations[0]?.prices || [], OWN_LOGISTIC]);
  };

  useEffect(() => {
    setDefaultDestination();
    updateLogisticOptions();
  }, [vasLogistics]);

  // if price list has been updated
  // first price option should be selected
  useEffect(() => {
    chooseFirstPrice();
  }, [logisticOptions]);

  const chooseFirstPrice = () => {
    if (logisticOptions && logisticOptions.length > 0) {
      const firstOption = logisticOptions[0].priceId;
      setValue(PRICE_NAME, `${firstOption}`);
    } else {
      setValue(PRICE_NAME, 'OWN');
    }
  };

  useEffect(() => {
    // we need to set prices array from choosed destination !!!
    setLogisticOptions([...choosedDestinationLocation?.payload?.prices || [], OWN_LOGISTIC]);
    chooseFirstPrice();
  }, [choosedDestinationLocation]);

  if (!vasLogistics) {
    return (<div>Loading logistic...</div>);
  }

  const { availableBuyerIncoterms, buyerIncoterms, logisticNeeded, departmentLocation, destinationLocations } = vasLogistics;

  const isNoLogisticOptions = logisticOptions.length === 1; // OWN only

  return (
    <>
      {/* {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <pre className="rhf-devtools left">{JSON.stringify(dirtyFields, null, 2)}</pre>} */}

      {/* REGULAR FLOW (offer / proposal) */}
      {!isShouldHideLogistics && (
        <>
          <div className="page-section-title">Logistics</div>
          <DataItemPreview
            item={vasLogistics.sellerIncoterms}
            title="SELLER INCOTERMS"
          />

          <Select
            label="BUYER INCOTERMS"
            name={BUYER_INCOTERMS}
            options={availableBuyerIncoterms}
            selected={buyerIncoterms}
          />

          {!logisticNeeded
            ? <div className="attention-message">{offerTexts.logistics.noNeed}</div>
            : (
              <div>
                <strong>
                  <span>{`From ${departmentLocation.address}`}</span>
                  <span> &rarr; </span>
                  {destinationsLength === 1 && <span>{destinationLocations[0].address}</span>}
                  {destinationsLength > 1 && choosedDestinationLocation && <span>{choosedDestinationLocation.label}</span>}
                </strong>
                <br />
                <br />
              </div>
            )}
          {destinationsLength > 1 && (
          <Select
            name={LOCATION_NAME}
            options={prepareLocationOptions(destinationLocations)}
            selected={null}
            rules={validationRules.required}
          />
          )}

          {choosedDestinationLocation && vasLogistics && (
          <>
            {isNoLogisticOptions && <NoLogisticsOptionsMsg />}

            <div
              className={clsx(
                'form-input',
                // isNoLogisticOptions && 'form-input--full-width',
                isNoLogisticOptions && 'visually-hidden',
              )}
            >
              <label>YOUR LOGISTICS HANDLER *</label>

              {/* TODO: temp solution, need retest all cases */}
              {/* {logisticOptions.length === 1 && <div className="attention-message">{offerTexts.logistics.noPrices}</div>} */}

              {logisticOptions.map((item: Price) => (
                <PriceOption
                  priceItem={item}
                  key={item.priceId}
                  name={PRICE_NAME}
                  iconName={item.type?.value}
                  caption={item?.caption}
                  currency={currency}
                />
              ))}

            </div>
          </>
          )}
        </>
      )}
      {/* REGULAR FLOW (offer / proposal) === END */}

      {/* PROPOSAL agreed (show info, no options needed) */}
      {isShouldHideLogistics && <RFPDeliveryVas />}
      {/* PROPOSAL agreed (show info, no options needed) === END */}

    </>
  );
};

export default OfferLogistic;
