import React from 'react';

const NoFinancingNeededMsg = () => (
  <div className="attention-message no-margin">
    <p>
      No financing needed for this option
    </p>

    <p>
      If you continue you will have to accept the seller&#8217;s payment terms for this offer.
    </p>
  </div>
);

export default NoFinancingNeededMsg;
