import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import FormattedPrice from 'SHARED/components/Price';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { Link } from 'react-router-dom';
import { offerLabels } from 'SHARED/helpers/texts';

export interface IOffspecOfferSummaryProps { }

const OffspecOfferSummary: React.FC<IOffspecOfferSummaryProps> = () => {
  const { OFF_SPEC } = useTypedSelector(
    (state) => state.offer,
  );

  const {
    title,
    description,
    documentCapabilities,
    packaging,
    paymentTerms,
    incoterms,
    loadAddress,
    logisticLocation,
    loadOptions,
    loadDateFrom,
    loadDateTo,
    endDate,
    price,
    documents,
    totalVolume,
    ownerOrg,
    status,
    salesTerms,
    deals,
    splittable,
    minBidVolume,
    currency,
  } = OFF_SPEC;

  const preparedVolume = typeof totalVolume === 'string' ? parseInt(totalVolume, 10) : totalVolume || 0;
  const total = price ? price * preparedVolume : 0;

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Product</div>
          <DataItemPreview item={title} title="AUCTION TITLE" />
          <DataItemPreview multiline multilineText={description} title="DESCRIPTION" />
          {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
          <DataItemPreview item={documentCapabilities} title={offerLabels.alreadyProduced.documents} />
          <DataItemPreview item={packaging} title={offerLabels.alreadyProduced.packaging} />
          <DataItemPreview item={salesTerms} title="SALES TERMS" />
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
          {(status === 'ARCHIVED') && deals && (deals?.length > 0) && (
            <>
              <br />
              <br />
              <Link to={`/offspec/deals/${deals[0].id}`}>
                View deal details
              </Link>
            </>
          )}
        </div>

        <div className="page-column">
          <div className="page-section-title">Shipping</div>
          <DataItemPreview item={incoterms} title="INCOTERMS" />
          {!!loadAddress?.value && <DataItemPreview item={loadAddress} title="LOADING ADDRESS" />}
          {!!logisticLocation && <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />}
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />
          <DataItemPreview datesRange={[loadDateFrom, loadDateTo]} title="PICK-UP DATE RANGE" />
        </div>

        <div className="page-column">
          <div className="page-section-title">Volume and price</div>

          <DataItemPreview icon={splittable ? 'icon-splittable' : ''} item={totalVolume} title="VOLUME" suffix="mt" />
          <DataItemPreview item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />
          {splittable && <DataItemPreview item={minBidVolume} title="MINIMUM BID VOLUME" suffix="mt" />}

          <FormattedPrice value={price || 0} title="ASK PRICE" suffix="/mt" prefix={currency} />
          <DataItemPreview item={paymentTerms} title="PAYMENT TERMS" />
          <FormattedPrice value={total} title="TOTAL PRICE" prefix={currency} />
        </div>

        <div className="page-column">
          <div className="page-section-title">Seller</div>
          <DataItemPreview item={ownerOrg.orgName} title="SELLER COMPANY" />
          <DataItemPreview item={ownerOrg.country} title="COUNTRY" />
        </div>

        <div className="page-column">
          <div className="page-section-title">Auction end date</div>
          <DataItemPreview item={endDate} isDate title="Auction end date" />
        </div>

      </div>

    </>
  );
};

export default OffspecOfferSummary;
