import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';

// ? message to the future DEV:
// ? a lot of extra params are required due to the backend validations
// ? perhaps in some version of perfect future this will change, but I would not count on it
interface IParams {
  id: string | number;
  remainingVolume: number | undefined;
  endDate: number | string;
  message: string;
}

export async function putProposalFeedback(params: IParams) {
  const {
    id,
    remainingVolume,
    endDate,
    message,
  } = params;

  const payload = {
    id,
    type: 'RFP_PROPOSAL',
    remainingVolume,
    endDate,
    feedback: {
      message,
    },
  };

  try {
    const response = await axios_Core.put('/v1/offers', payload);

    if (response.status === 200) {
      notification({
        type: 'success',
        title: 'Success',
        message: 'Thank you for your feedback!',
      });

      return 'success';
    }

    throw new Error('Post proposal feedback error');
  } catch (error) {
    console.error('Post proposal feedback error:', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not send the data...',
    });

    return 'error';
  }
}
