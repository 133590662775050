import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import Menu from 'SHARED/components/Menu';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { HandleRowClick, MenuItem } from 'SHARED/types/offerTypes';
import routes from 'SHARED/types/routes';
import OfferTable from 'SHARED/pages/OffersList/OfferTable';
import RfpSummary from 'pages/RFP/RfpSummary';

interface RouteParams {
  id: string,
}

const menuItems: MenuItem[] = [
  { label: 'Proposals', link: 'proposals' },
  { label: 'Details', link: 'details' },
];

const RfpDetailPage: React.FC = () => {
  const { getOfferDetails, resetOffer } = useActions();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const { RFP, loading } = useTypedSelector((state) => state.offer);

  const [activeTab, setActiveTab] = useState<string>(menuItems[0].link);

  const {
    created,
    productCategory,
    productType,
    productTitle,
    endDate,
    proposals,
    minVolume,
    maxVolume,
    status,
  } = RFP;

  useEffect(() => {
    fetchOfferDetails();

    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'RFP' });
    } catch (err) {
      history.push(routes.rfp.list);
    }
  };

  const changeTabAction = (tab: string) => {
    setActiveTab(tab);

    if (tab === 'proposals') {
      getOfferDetails({ id, offerType: 'ON_SPEC' });
    }
  };

  const RFP_TITLE = `RFP: ${productCategory?.label} ${productType?.label} (${productTitle}) ${minVolume} - ${maxVolume} mt`;

  const viewProposalDetails: HandleRowClick = (e, offerId) => {
    e.preventDefault();
    history.push(`/rfp/proposals/${offerId}`);
  };

  const isActive = status === 'ACTIVE';

  return (
    <>
      <Helmet>
        <title>{RFP_TITLE}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to={isActive ? routes.rfp.defaultList : routes.rfp.archivedList}>&lt; All rfps </Link>
      </div>
      <div className="page-body is-logged-in rfp-page">
        <h1 className="page-title">{RFP_TITLE}</h1>

        <div className="offer-auction-details">
          <div>
            <DataItemPreview item={created} isDate title="CREATED" />
          </div>

          <div>
            <DataItemPreview title="ENDS" isDate item={endDate} />
          </div>

          <div>
            <DataItemPreview item={proposals?.length || 0} title="Proposals" />
          </div>
        </div>

        <Menu
          items={menuItems}
          view="in-page-tabs"
          activeLink={activeTab}
          changeTabAction={changeTabAction}
        />

        {activeTab === 'details' && <RfpSummary mode="detail" />}

        {activeTab === 'proposals' && (
          <>
            <div className="page-section-title">Received proposals</div>
            {proposals && proposals.length === 0
              ? <div className="attention-message">No seller has reacted to this RFP</div> : (
                <OfferTable
                  data={proposals}
                  pageType="proposals"
                  handleRowClick={viewProposalDetails}
                  activeTab="offers"
                />
              )}
          </>
        )}

      </div>
    </>
  );
};

export default RfpDetailPage;
