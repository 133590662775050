import React from 'react';
import Checkbox from 'SHARED/components/Checkboxes';
import { useForm, FormProvider, UseFormWatch } from 'react-hook-form';
import FormattedPrice from 'SHARED/components/Price';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { OfferBid } from 'SHARED/types/bidTypes';
import texts from 'SHARED/helpers/texts';
import useCalculateOfferPrices from 'hooks/useCalculateOfferPrices';

interface PropsNewBidDetails {
  bid: OfferBid,
  remainingVolume: number,
  handleClose: (value: React.SetStateAction<boolean>) => void,
  submitBid: () => void,
  // handler for live prices
  watch: UseFormWatch<any>;
  isSimpleOffer?: boolean
}

const NewBidDetails: React.FC<PropsNewBidDetails> = ({
  handleClose,
  remainingVolume,
  submitBid,
  bid,
  watch,
  isSimpleOffer = false,
}) => {
  const methods = useForm();
  const { personalOffer, price: offerOriginalPrice } = useTypedSelector((state) => state.offer.ON_SPEC);

  const isHavePersonalOffer = !!personalOffer;

  const { currency } = bid;

  const calculatedPrices = useCalculateOfferPrices({
    type: 'offer', volume: remainingVolume, price: (offerOriginalPrice || 0), watch,
  });

  const offerVolume = isHavePersonalOffer ? personalOffer?.volume : remainingVolume;
  const offerLogisticPrice = isHavePersonalOffer ? personalOffer?.logisticPrice : calculatedPrices?.logisticPrice;
  const offerCreditPrice = isHavePersonalOffer ? personalOffer?.creditPrice : calculatedPrices?.creditPrice;

  // original pricing details (including logistic and credit)
  const getOfferPrice = () => {
    const netPrice = isHavePersonalOffer ? personalOffer?.netPrice : calculatedPrices?.netPrice;
    const logisticPrice = isHavePersonalOffer ? personalOffer?.logisticPrice : calculatedPrices?.logisticPrice;
    const creditPrice = isHavePersonalOffer ? personalOffer?.creditPrice : calculatedPrices?.creditPrice;

    return (netPrice || 0) + (logisticPrice || 0) + (creditPrice || 0) + (bid?.packagingPrice || 0);
  };

  const offerTotalPrice = getOfferPrice() * (offerVolume || 0);

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a
          href="##"
          className="popup-close"
          onClick={(e) => { handleClose(false); }}
          data-testing="popup-close-button"
        >
          close
        </a>

        <div>
          <h1>{texts.counterBid.title}</h1>
          <div>{texts.counterBid.description}</div>

          <br />

          <table className="new-bid-details-table">
            <tbody>
              <tr>
                <td><DataItemPreview item={offerVolume} title="Offer volume" suffix="mt" /></td>
                <td><DataItemPreview item={bid?.volume} title="our bid volume" suffix="mt" className="green-text" /></td>
              </tr>
              <tr>
                <td><FormattedPrice suffix="/mt" prefix={currency} value={getOfferPrice()} title="Offer price" /></td>
                <td><FormattedPrice suffix="/mt" prefix={currency} value={bid?.totalPrice} title="Our bid price" className="green-text" /></td>
              </tr>

              {!isSimpleOffer && (
                <>
                  {/* "INCLUDING" column headings */}
                  <tr>
                    <td>
                      <div className="highlight">Including:</div>
                    </td>
                    <td>
                      <div className="highlight">Including:</div>
                    </td>
                  </tr>

                  {/* PACKAGING DIFFERENTIAL */}
                  <tr>
                    <td>
                      <div className="label-text">Packaging differential</div>
                      <FormattedPrice value={bid?.packagingPrice} suffix="/mt" prefix={currency} />
                    </td>
                    <td>
                      <div className="label-text">Packaging differential</div>
                      <FormattedPrice value={bid?.packagingPrice} suffix="/mt" prefix={currency} className="green-text" />
                    </td>
                  </tr>

                  {/* LOGISTICS */}
                  <tr>
                    <td>
                      <div className="label-text">Logistic</div>
                      <FormattedPrice value={offerLogisticPrice} suffix="/mt" prefix={currency} />
                    </td>
                    <td>
                      <div className="label-text">Logistic</div>
                      <FormattedPrice value={bid?.logisticPrice} suffix="/mt" prefix={currency} className="green-text" />
                    </td>
                  </tr>

                  {/* FINANCING */}
                  <tr>
                    <td>
                      <div className="label-text">Financing</div>
                      <FormattedPrice value={offerCreditPrice} suffix="/mt" prefix={currency} />
                    </td>
                    <td>
                      <div className="label-text">Financing</div>
                      <FormattedPrice value={bid?.creditPrice} suffix="/mt" prefix={currency} className="green-text" />
                    </td>
                  </tr>
                </>
              )}

              {/* TOTAL */}
              <tr>
                <td>
                  <FormattedPrice prefix={currency} value={offerTotalPrice} title="Total offer price" priceClassName="highlight" />
                </td>
                <td>
                  <FormattedPrice prefix={currency} value={bid?.totalOfferPrice} title="Total bid price" className="green-text" priceClassName="highlight" />
                </td>
              </tr>
            </tbody>
          </table>

          <DataItemPreview item={bid?.message} title="Message for the seller" />
          <DataItemPreview item={bid?.validTo} isDate title="Bid valid until" />

          <div>
            <FormProvider {...methods}>
              <form action="" onSubmit={methods.handleSubmit(submitBid)}>
                <Checkbox
                  name="agree-bid"
                  values={[{ value: 1, label: texts.counterBid.agreeDescription }]}
                  rules={{ required: texts.counterBid.agreeValidation }}
                  testingName="agree-bid-checkbox"
                />
                <div>
                  <button
                    type="submit"
                    className="btn-primary"
                    data-testing="popup-submit-button"
                  >
                    Place my bid
                  </button>

                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={(e) => { handleClose(false); }}
                    data-testing="popup-cancel-button"
                  >
                    Back to offer
                  </button>
                </div>
              </form>

            </FormProvider>

          </div>

        </div>
      </div>
    </div>
  );
};
export default NewBidDetails;
