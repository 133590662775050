import { BidFormFields, CommonBidFields, PriceParams } from 'SHARED/types/bidTypes';

type CommonFields = (form:BidFormFields, id: number | string, version: string) => CommonBidFields;
export const commonBidFields:CommonFields = (form, id, version) => {
  const { logisticLocationId, logisticPriceId, paymentTerm, paymentOffset, creditConditionId, packaging } = form;

  const fields:CommonBidFields = {
    bidId: null,
    logisticLocationId: logisticLocationId?.payload.id || null,
    logisticPriceId: logisticPriceId === 'OWN' ? null : logisticPriceId,
    offerId: id,
    packaging: packaging ? packaging.value : null, // only for TOBE PRODUCED OFFERS
    offerVersion: version,
    paymentOffset: paymentOffset ? parseInt(paymentOffset.value, 10) : null,
    paymentTerm: paymentTerm ? paymentTerm.value : null,
    creditConditionId: creditConditionId === 'OWN' ? null : creditConditionId,
  };

  // if (bidForm.logisticPriceId === 'OWN') {
  //   fields.logisticLocationId = vasLogistics?.destinationLocations[0].id;
  // }
  return fields;
};

type Prices = (form:BidFormFields, id: number | string, version: string, defaultPrice: any, defaultVolume: any, isIncludeVas: boolean) => PriceParams;
export const PricesPayload:Prices = (form, id, version, defaultPrice, defaultVolume, isIncludeVas) =>
  // const { price = defaultPrice, volume = defaultVolume } = form;
  // console.log(form.price, defaultPrice);
  ({
    ...commonBidFields(form, id, version),
    buyerIncoterms: form.buyerIncoterms?.value || null,
    // price: form.price ? parseFloat(form.price.replace(',', '.')) : defaultPrice,
    // volume: form.volume ? form.volume : defaultVolume,
    price: defaultPrice,
    volume: defaultVolume,
    isIncludeVas,
    isAcceptingOffer: true,
  });
export default PricesPayload;
