import React, { FC } from 'react';
import MailLink from 'SHARED/components/MailLink';

const FinancingNotExistMsg: FC = () => (
  <div className="attention-message no-margin">
    <p>
      Currently there is no financing available for this offer. Please <MailLink text="contact us" /> for any assistance.
    </p>

    <p>
      If you continue you will have to accept the seller&#8217;s payment terms for this offer.
    </p>
  </div>
);

export default FinancingNotExistMsg;
