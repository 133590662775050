/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Checkbox from 'SHARED/components/Checkboxes';
import { useForm, FormProvider } from 'react-hook-form';
import FormattedPrice from 'SHARED/components/Price';
import { counterBid } from 'SHARED/helpers/texts';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { OfferBid } from 'SHARED/types/bidTypes';

interface PropsAcceptOffer {
  bid: OfferBid | null,
  handleClose: (value: React.SetStateAction<boolean>) => void,
  submitBid: () => void,
  type?: 'offer' | 'proposal'
  isSimpleOffer?: boolean
}

const AcceptOfferSummary: React.FC<PropsAcceptOffer> = ({
  handleClose,
  submitBid,
  bid,
  type = 'offer',
  isSimpleOffer = false,
}) => {
  const methods = useForm();
  const { currency } = bid || {};

  const isOffer = type === 'offer';
  const offerTypeName = isOffer ? 'Offer' : 'Proposal';

  const handleConfirmBid = async () => {
    submitBid();
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a
          href="##"
          className="popup-close"
          onClick={(e) => { handleClose(false); }}
          data-testing="popup-close-button"
        >
          close
        </a>

        <div>
          <h1>{`You are about to accept this ${offerTypeName.toLowerCase()}!`}</h1>
          <div>
            {`After accepting this ${offerTypeName.toLowerCase()} the seller will contact you regarding the handling of the deal. If you have any questions please don't hesitate to contact us!`}
          </div>

          <br />

          <table className="new-bid-details-table">
            <tbody>
              <tr>
                <td>
                  <DataItemPreview
                    item={bid?.volume}
                    title={`${offerTypeName} volume`}
                    suffix="mt"
                  />
                </td>
              </tr>
              <tr>
                <td><FormattedPrice suffix="/mt" prefix={currency} value={bid?.totalPrice} title="Total price" /></td>
              </tr>

              {!isSimpleOffer && (
                <>
                  {/* "INCLUDING" column heading */}
                  <tr>
                    <td>
                      <div className="highlight">Including:</div>
                    </td>
                  </tr>

                  {/* PACKAGING DIFFERENTIAL */}
                  <tr>
                    <td>
                      <div className="label-text">Packaging differential</div>
                      <FormattedPrice value={bid?.packagingPrice} suffix="/mt" prefix={currency} />
                    </td>
                  </tr>

                  {/* LOGISTICS */}
                  <tr>
                    <td>
                      <div className="label-text">Logistic</div>
                      <FormattedPrice value={bid?.logisticPrice} suffix="/mt" prefix={currency} />
                    </td>
                  </tr>

                  {/* FINANCING */}
                  <tr>
                    <td>
                      <div className="label-text">Financing</div>
                      <FormattedPrice value={bid?.creditPrice} suffix="/mt" prefix={currency} />
                    </td>
                  </tr>
                </>
              )}

              {/* TOTAL */}
              <tr>
                <td>
                  <FormattedPrice
                    prefix={currency}
                    value={bid?.totalOfferPrice}
                    title={`Total ${offerTypeName.toLowerCase()} price`}
                    priceClassName="highlight"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <FormProvider {...methods}>
              <form action="" onSubmit={methods.handleSubmit(handleConfirmBid)}>
                <Checkbox
                  name="agree-bid"
                  values={[{ value: 1, label: isOffer ? counterBid.acceptOffer : counterBid.acceptProposal }]}
                  rules={{ required: counterBid.agreeValidation }}
                />
                <div>
                  <button
                    type="submit"
                    className="btn-primary"
                    data-testing="popup-submit-button"
                  >
                    Accept {offerTypeName.toLowerCase()}
                  </button>

                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={(e) => { handleClose(false); }}
                    data-testing="popup-cancel-button"
                  >
                    Back to {offerTypeName.toLowerCase()}
                  </button>
                </div>
              </form>

            </FormProvider>

          </div>

        </div>
      </div>
    </div>
  );
};
export default AcceptOfferSummary;
