import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertToDictionary } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { BidForm, UpdateVasHandler } from 'types/offerBidTypes';
import OfferFinancing from './OfferFinancing';
import OfferLogistic from './OfferLogistic';

interface IProps {
  updateVas: (handlerType: UpdateVasHandler) => (isIncludeVas: boolean) => void
}

// TBP packaging
const PACKAGING = 'form.packaging';

// logistics
const BUYER_INCOTERMS = 'form.buyerIncoterms';
// const LOCATION_NAME = 'form.logisticLocationId';
const LOGISTICS_PRICE = 'form.logisticPriceId';

// financing
const PAYMENT_TERM = 'form.paymentTerm';
const PAYMENT_OFFSET = 'form.paymentOffset';
const CREDIT_CONDITION = 'form.creditConditionId';

const VasSection: FC<IProps> = ({ updateVas }) => {
  const updatePricesAndFinancing = updateVas('prices-and-financing');
  const updateLogistics = updateVas('logistics');
  const updateFinancing = updateVas('financing');

  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { watch, formState: { dirtyFields }, setValue } = useFormContext<BidForm>();

  const { version, type, agreedOnPayment, agreedOnDelivery } = ON_SPEC;
  const isProposal = type?.value === 'RFP_PROPOSAL';
  const isShouldHideLogistics = isProposal && agreedOnDelivery;
  const isShouldHideFinancing = isProposal && agreedOnPayment;

  const memoizedOfferVersion = useMemo(() => version, [version]);
  const [currentVersion, setCurrentVersion] = useState(version);

  // TBP packaging
  const packaging = watch(PACKAGING);

  // logistics
  const buyerIncoterms = watch(BUYER_INCOTERMS);
  // const logisticLocationId = watch(LOCATION_NAME);
  const logisticPriceId = watch(LOGISTICS_PRICE);

  // level 1 - incoterms or version changed (not initial load) => fetch new logistics with VAS
  useEffect(() => {
    if (isShouldHideLogistics) return;

    if (dirtyFields.form?.buyerIncoterms) {
      setValue(LOGISTICS_PRICE, null);
      updateLogistics(true);
    }
  }, [buyerIncoterms]);

  // level 2 - location changed - no need to do anything
  // logisticPriceId` will innitiate needed actions

  // level 3 - price changed (not initial load) => update VAS for financing
  useEffect(() => {
    if (isShouldHideLogistics) return;

    updateFinancing(true);
  }, [logisticPriceId]);

  // financing
  const paymentTerm = watch(PAYMENT_TERM);
  const creditConditionId = watch(CREDIT_CONDITION);
  const paymentOffset = watch(PAYMENT_OFFSET);

  // level 1.1 - payment term changed (not initial load)
  // if no offset possible => clear payment offset
  // if offset possible => set default offset, offset useEffect will update VAS for financing
  useEffect(() => {
    if (isShouldHideFinancing) return;

    if (dirtyFields.form?.paymentTerm) {
      if (paymentTerm) {
        if (!paymentTerm.hasDayOffset) {
          setValue(PAYMENT_OFFSET, undefined);
        }

        if (paymentTerm.hasDayOffset) {
          const firstElement = paymentTerm.offsetDays[0];
          setValue(PAYMENT_OFFSET, convertToDictionary(firstElement));
        }
      }
    }
  }, [paymentTerm]);

  // OPTIONAL - level 1.2 - paymentOffset changed => fetch new financing with VAS
  useEffect(() => {
    if (isShouldHideFinancing) return;

    updateFinancing(true);
    setValue(CREDIT_CONDITION, null);
  }, [paymentOffset]);

  // level 2 - creditConditionId changed => update VAS for logistics
  useEffect(() => {
    if (isShouldHideFinancing) return;

    updateFinancing(false);
  }, [creditConditionId]);

  // EXTRA
  // if actual version changed => fetch new logistics and financing with VAS
  // can happen upon accepting offer or placing bid
  useEffect(() => {
    if (currentVersion !== memoizedOfferVersion) {
      updatePricesAndFinancing(true);
      setCurrentVersion(memoizedOfferVersion);
    }
  }, [memoizedOfferVersion]);

  // if packaging changed => fetch new financing with VAS
  useEffect(() => {
    if (isProposal) return;

    if (dirtyFields.form?.packaging) {
      updateFinancing(true);
    }
  }, [packaging]);

  return (
    <>
      <OfferLogistic updatePrices={updateFinancing} />
      <OfferFinancing updatePrices={updateLogistics} />
    </>
  );
};

export default VasSection;
