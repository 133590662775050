import React, { useState, useEffect } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { handleDateRange } from 'SHARED/helpers/common';

export interface IAlreadyProducedOfferSummaryProps {
  mode: 'preview' | 'detail'
}

const RfpSummary: React.FC<IAlreadyProducedOfferSummaryProps> = ({ mode }) => {
  const { RFP } = useTypedSelector((state) => state.offer);
  const { me } = useTypedSelector((state) => state.users);
  const { product: productDetails } = useTypedSelector((state) => state.specs);
  const { getProduct } = useActions();

  const {
    productType,
    productCategory,
    packages,
    endDate,
    documents,
    deliveryLocation,
    departureFrom,
    departureTo,
    product,
    description,
    documentCapabilities,
    productCharacteristics,
    productTitle,
    minVolume,
    maxVolume,
    incoterms,
    paymentTerms,
    paymentOffset,
    status,
  } = RFP;

  // flags
  const isPreview = mode === 'preview';
  const isActive = status === 'ACTIVE';
  const isBuyer = me.type === 'BUYER';
  // flags === END

  const paymentTermsValue = (isPreview && paymentOffset)
    ? paymentTerms?.label?.replace(' X days', ` ${paymentOffset} days`)
    : paymentTerms?.label;

  useEffect(() => {
    if (product?.value) {
      getProduct(product?.value);
    }
  }, []);

  const [isPopup, setIsPopup] = useState(false);

  const handleClose = () => {
    setIsPopup(false);
  };

  const departure = handleDateRange({ from: departureFrom, to: departureTo });

  return (
    <>
      {mode === 'detail' && isBuyer && isActive && (
        <div className="attention-message no-margin">
          You cannot change an active RFP, please wait until the RFP period has expired. If you need any immediate assistance, please contact our support department.
        </div>
      )}
      <div className="page-columns">

        <div className="page-column">
          <div className="page-section-title">Product</div>

          <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
          <DataItemPreview item={productType} title="PRODUCT TYPE" />

          <div className="data-preview-row">
            <div className="label-text">BUYER PRODUCT SPECIFICATION</div>
            <div>{productTitle || productDetails?.title}</div>
            <div><a href="#open-spec-popup" onClick={(e) => setIsPopup(true)}>Specifications details</a></div>
          </div>

          <DataItemPreview item={description} title="INSTRUCTION FOR THE SELLERS" />
          {documents && documents.SALES_TERMS && (
            <DataItemPreview file={documents.SALES_TERMS[0]} title="ATTACHED DOCUMENT" />
          )}
          <DataItemPreview item={packages} title="PACKAGING ALLOWED" />
          <DataItemPreview item={documentCapabilities} title="DOCUMENTS REQUIRED" />
        </div>

        <div className="page-column">
          <div className="page-section-title">Shipping</div>

          <DataItemPreview item={incoterms} title="INCOTERMS" />
          <DataItemPreview item={deliveryLocation?.fullAddress} title="DELIVERY LOCATION" />
          <DataItemPreview item={departure} title="DELIVERY" />
          <DataItemPreview item={paymentTermsValue} title="PAYMENT TERMS" />
          {/* {!!paymentOffset && isPreview && (
            <DataItemPreview item={`${paymentOffset} days`} title="PAYMENT OFFSET" />
          )} */}
        </div>

        <div className="page-column">
          <div className="page-section-title">Proposal volume</div>
          {minVolume === maxVolume
            ? <DataItemPreview item={`${minVolume} mt`} title="VOLUME" />
            : <DataItemPreview item={`${minVolume} - ${maxVolume} mt`} title="VOLUME" />}

          <div className="page-section-title">RFP end date</div>
          <DataItemPreview item={endDate} isDate title="RFP VALID UNTIL" />
        </div>

      </div>

      {isPopup && (
        <ProductSpecsDetailsModal
          handleClose={() => handleClose()}
          characteristics={productCharacteristics || productDetails?.characteristics}
        />
      )}

    </>
  );
};

export default RfpSummary;
