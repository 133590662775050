import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useCalculateOfferPrices from 'hooks/useCalculateOfferPrices';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import TableSpacer from 'SHARED/components/TableSpacer';
import PriceTableRow, { ITableRow } from './PriceTableRow';

interface IProps {
  bidVolume: number;
  bidPrice: number;
}

const BidLivePreview: FC<IProps> = ({
  bidVolume,
  bidPrice,
}) => {
  const { ON_SPEC: { type, priceDetails, currency } } = useTypedSelector((state) => state.offer);
  const { watch } = useFormContext();

  // flags
  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags === end

  const {
    netPrice,
    packagingPrice,
    logisticPrice,
    selectedLogisticsType,
    creditPrice,
  } = useCalculateOfferPrices({ type: 'bid', volume: bidVolume, price: bidPrice, watch });

  const packageRow = { value: packagingPrice, label: 'Packaging differential' };

  const pricesRows: ITableRow[] = [
    { value: netPrice, label: 'Net Price' },
  ];

  const vasRows: ITableRow[] = [
    {
      value: logisticPrice,
      label: 'Logistic',
      byInfo: priceDetails?.logisticProvider?.value,
      icon: selectedLogisticsType,
    },
    {
      value: creditPrice,
      label: 'Financing',
      byInfo: priceDetails?.financingProvider?.label,
    },
  ];

  if (!isSimpleOffer) {
    pricesRows.splice(1, 0, ...vasRows);
  }

  if (isToBeProduced) {
    pricesRows.splice(1, 0, packageRow);
  }

  return (
    <div className="bid-details-preview">

      <table className="bid-table bid-table--preview">
        <tbody>
          <tr className="bold-text">
            <td>Bid volume: </td>
            <td>{bidVolume ? `${bidVolume} mt` : '-'}</td>
          </tr>

          <PriceTableRow
            label="Bidding price"
            value={bidPrice}
            currency={currency}
            bold
          />

          <TableSpacer />

          {pricesRows.map((p) => (
            <PriceTableRow
              {...p}
              currency={priceDetails?.currency}
              key={p.label}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BidLivePreview;
