import PriceTableRow, { ITableRow } from 'pages/Onspec/OfferDetails/Partials/PriceTableRow';
import React, { FC } from 'react';
import TableSpacer from 'SHARED/components/TableSpacer';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { Bid, OfferStatus } from 'SHARED/types/offerTypes';

interface IProps {
  bid: Bid
  onDeleteBid: (arg: any) => any
  auctionStatus: OfferStatus
}

const AuctionLatestBidSection: FC<IProps> = ({
  bid,
  onDeleteBid,
  auctionStatus,
}) => {
  const { userTimezone, userTimezoneAbbr } = useUserTimezone();

  const prices: ITableRow[] = [
    { value: bid.bidVolume || 0, label: 'Volume', suffix: 'mt' },
    { value: bid.value || 0, label: 'Price', currency: null },
    { value: (bid.value * bid.bidVolume) || 0, label: 'Total', suffix: null, bold: true },
  ];

  return (
    <div
      className="offer-auction-details bid-section"
      data-testing="latest-bid-section"
    >
      <div className="page-section-title">Our latest bid</div>

      <p>
        {`${dateWithTimezone(bid?.created, userTimezone)} ${userTimezoneAbbr} by ${bid.userName}`}
      </p>

      <table className="bid-table bid-table--preview">
        <tbody>
          <tr>
            <td>Volume:</td>
            <td>{bid.bidVolume} mt</td>
          </tr>
          <PriceTableRow {...prices[1]} currency={bid.currency} />

          <TableSpacer />

          <PriceTableRow {...prices[2]} currency={bid.currency} />
        </tbody>
      </table>

      {/* {bid.deletable && ()} */}
      {auctionStatus === 'ACTIVE' && (
        <button
          type="button"
          className="btn-icon"
          onClick={(e) => onDeleteBid(e)}
          data-testing="delete-bid-button"
        >
          <i className="icon-delete" />
        </button>
      )}
    </div>
  );
};

export default AuctionLatestBidSection;
