import React, { useEffect } from 'react';
import Oauth from 'SHARED/pages/Oauth';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';

import routes from 'SHARED/types/routes';

import Profile from 'SHARED/pages/Profile';

import ProductSpecifications from 'SHARED/pages/ProductCategoriesPage';
import CreateProduct from 'SHARED/pages/ProductCreatePage';
import PreviewProduct from 'SHARED/pages/ProductPreviewPage';

import OffersList from 'SHARED/pages/OffersList';

import OnspecOfferDetails from 'pages/Onspec/OfferDetails';
import OnspecDealDetails from 'pages/Onspec/DealDetails/OnspecDealDetails';

import OffspecOfferPage from 'pages/Offspec/OfferDetails/OffspecOfferPage';
import OffspecDealPage from 'pages/Offspec/DealDetails/OffspecDealPage';

import RfpCreate from 'pages/RFP/Create';
import RfpPreview from 'pages/RFP/Preview';
import RfpDetails from 'pages/RFP/Details';

import ReactNotification from 'react-notifications-component';
import texts from 'SHARED/helpers/texts';
import OfferProvideDocument from 'SHARED/pages/Documents/OfferProvideDocument';
import OfferRequestDocument from 'SHARED/pages/Documents/OfferRequestDocument';
import TheHeader from './SHARED/components/TheHeader';
import TheLogo from './SHARED/components/TheLogo';
import 'react-notifications-component/dist/theme.css';
import './common-styles.scss';

const App = () => {
  const { getUser } = useActions();

  const { me } = useTypedSelector((state) => state.users);
  const { isLoggedIn } = useTypedSelector((state) => state.oauth);

  // it's impossible to use router hook in upper scope TODO: refactor needed
  // check if current page is not oauth app's page
  const url = window.location.href;

  const notOauthPage = url.indexOf(routes.auth.oauthLogin) === -1;

  useEffect(() => {
    // get user info if user has logged in
    if (isLoggedIn) {
      getUser();
    }

    // if not logged in and the page is not oauth page
    // redirect to oauth service
    if (!isLoggedIn && notOauthPage) {
      console.log('!isLoggedIn && notOauthPage');
      localStorage.setItem('route', window.location.pathname);

      if (window.location.hostname === 'localhost') {
        console.log('provide access token');
      } else {
        window.location.href = `${routes.auth.oauth}/login`;
      }
    }
  }, [isLoggedIn]);

  const userName = `${me.profile?.firstName} ${me.profile?.middleName || ''} ${me.profile?.lastName}`;

  return (
    <Router>

      <ReactNotification />
      {isLoggedIn
        ? (
          <TheHeader
            orgName={me.organization?.orgName}
            userName={userName}
          />
        )
        : <TheLogo />}

      <div className={`container ${isLoggedIn ? 'is-logged-in' : ''}`}>

        <Switch>
          {isLoggedIn && <Redirect from="/" to={routes.onspec.listDefault} exact />}
          {isLoggedIn && <Redirect from={routes.auth.oauthLogin} to={routes.onspec.listDefault} exact />}

          <Route exact path={routes.rfp.create}>
            <RfpCreate />
          </Route>

          <Route exact path={routes.rfp.preview}>
            <RfpPreview />
          </Route>

          <Route exact path={[routes.rfp.details, routes.rfp.archivedDetails]}>
            <RfpDetails />
          </Route>

          <Route exact path={routes.rfp.proposalDetails}>
            <OnspecOfferDetails isLoggedIn={isLoggedIn} />
          </Route>

          <Route exact path={routes.auth.oauthLogin}>
            <Oauth />
          </Route>

          <Route exact path={routes.common.profile}>
            <Profile />
          </Route>

          <Route exact path={routes.common.specifications}>
            <ProductSpecifications
              title={texts.specsTexts.buyer.title}
              description={texts.specsTexts.buyer.description}
            />
          </Route>

          <Route exact path={routes.common.createProduct()}>
            <CreateProduct isForBuyer />
          </Route>

          <Route exact path={routes.common.productDetails()}>
            <PreviewProduct isForBuyer />
          </Route>

          {/* ordering is important DEAL must be first */}
          <Route
            exact
            path={routes.offspec.dealDetail}
          >
            <OffspecDealPage isLoggedIn={isLoggedIn} />
          </Route>

          <Route
            exact
            path={[
              // regular offers
              routes.onspec.dealDetail,
              routes.onspec.dealDetail_documents,

              // RFPs
              routes.rfp.dealDetail,
              routes.rfp.dealDetail_documents,
            ]}
          >
            <OnspecDealDetails isLoggedIn={isLoggedIn} />
          </Route>

          <Route
            exact
            path={[
              routes.onspec.provideDocument,
              routes.rfp.provideDocument,
            ]}
          >
            <OfferProvideDocument />
          </Route>

          <Route
            exact
            path={routes.onspec.requestDocument}
          >
            <OfferRequestDocument />
          </Route>

          <Route exact path={routes.onspec.details}>
            <OnspecOfferDetails isLoggedIn={isLoggedIn} />
          </Route>

          <Route
            exact
            path={routes.offspec.offerDetail}
          >
            <OffspecOfferPage isLoggedIn={isLoggedIn} />
          </Route>

          {/* all dynamic/params-driven routes should be at the end */}
          {/* in order to avoid conflicts (otherwise some paths will be unreachable) */}
          <Route exact path={routes.offersList}>
            <OffersList isLoggedIn={isLoggedIn} portal="BUYER" />
          </Route>

        </Switch>

      </div>

    </Router>
  );
};

export default App;
