import React, { FC } from 'react';
import MailLink from 'SHARED/components/MailLink';

const NoFinancingOptionsMsg: FC = () => (
  <div className="attention-message no-margin">
    <p>
      There is no financing available for the  payment terms you selected. Please select other terms or <MailLink text="contact us" /> if you need any assistance.
    </p>

    <p>
      If you continue you will have to accept the seller&#8217;s payment terms for this offer.
    </p>
  </div>
);

export default NoFinancingOptionsMsg;
