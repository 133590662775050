import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const OfferDealLogisticsWithHandler = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    agreedOnDelivery,
    deal,
    departureFrom,
    departureTo,
    incoterms,
    logisticLocation,
  } = ON_SPEC;

  const {
    shipping,
    currency,
    volumeUnits,
  } = deal || {};

  const {
    buyerIncoterms,
    destinationLocation,
    logisticHandler,
    logisticPrice,
    logisticAmount,
  } = shipping || {};

  // flags
  const isLand = shipping?.containerType?.value?.toLowerCase() === 'land';
  // flags === END

  if (!id || agreedOnDelivery || !logisticHandler?.value) return null;

  return (
    <>
      <DataItemPreview
        title="Departure"
        datesRange={[departureFrom, departureTo]}
      />

      <DataItemPreview
        title="Seller incoterms"
        item={incoterms}
      />

      <DataItemPreview
        title="Seller logistics location"
        item={logisticLocation?.fullAddress}
      />

      <DataItemPreview
        title="Buyer incoterms"
        item={buyerIncoterms}
      />

      <DataItemPreview
        title="Buyer location"
        item={destinationLocation?.address}
      />

      <DataItemPreview
        title="Logistics provider"
        item={logisticHandler?.label}
        icon={isLand ? 'icon-land' : 'icon-sea'}
      />

      <FormattedPrice
        title="Const of logistics"
        value={logisticPrice}
        prefix={currency}
        suffix={`/${volumeUnits?.toLowerCase()}`}
      />

      <FormattedPrice
        title="Total cost of logistics"
        value={logisticAmount}
        prefix={currency}
      />

    </>
  );
};

export default OfferDealLogisticsWithHandler;
