import React from 'react';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import texts from 'SHARED/helpers/texts';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { CounterBid } from 'SHARED/types/offerTypes';
import TableSpacer from 'SHARED/components/TableSpacer';
import PriceTableRow, { ITableRow } from './PriceTableRow';

export interface Props {
  acceptBid: (bidId: number) => void,
  bid: CounterBid
  currency: string
}

const CounterOffer: React.FC<Props> = ({ bid, currency, acceptBid }) => {
  const { userTimezone, userTimezoneAbbr } = useUserTimezone();
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const isSimpleOffer = ON_SPEC?.type?.value === 'SIMPLE_ALREADY_PRODUCED';

  const vasFields = !isSimpleOffer ? [
    { value: bid.logisticPrice || 0, label: 'Logistic', byInfo: bid.logisticHandler?.label },
    { value: bid.creditPrice || 0, label: 'Financing', byInfo: bid.financingProvider?.label },
  ] : [];

  const prices: ITableRow[] = [
    { value: bid.netPrice || 0, label: 'Price' },
    ...vasFields,
    { value: bid.totalPrice || 0, label: 'Total', bold: true },
  ];

  return (
    <div className="offer-auction-details prebid-info-section">

      <div>
        <div><strong>{`Counter offer ${dateWithTimezone(bid.created, userTimezone)} ${userTimezoneAbbr}`}</strong></div>

        <p>{texts.counterOffer.description}</p>

        <table className="bid-table bid-table--preview">
          <tbody>

            <tr>
              <td>Volume: </td>
              <td>{`${bid.volume} mt`}</td>
            </tr>

            <TableSpacer />

            {prices.map((p) => (
              <PriceTableRow {...p} currency={currency} key={p.label} />
            ))}

            <tr>
              <td colSpan={2} style={{ paddingBottom: 12, paddingRight: 0 }}>
                <OfferDisclaimer userType="buyer" newTab />
              </td>
            </tr>

            <tr>
              <td>Message: </td>
              <td>{bid.message}</td>
            </tr>

          </tbody>
        </table>

        <br />

        <button type="button" className="btn-primary" onClick={() => { acceptBid(bid.id || 0); }}>Accept this counter offer</button>
      </div>

    </div>
  );
};

export default CounterOffer;
