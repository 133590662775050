import React, { useState } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import FormattedPrice from 'SHARED/components/Price';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { MpcDocLink, getFullName } from 'SHARED/helpers/common';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import RfpDealLogisticsAgreed from './fields/logistics/RfpDealLogisticsAgreed';
import OfferDealLogisticsWithHandler from './fields/logistics/OfferDealLogisticsWithHandler';
import OfferDealLogisticsNoHandler from './fields/logistics/OfferDealLogisticsNoHandler';
import RfpDealFinancingAgreed from './fields/financing/RfpDealFinancingAgreed';
import OfferDealFinancingWithHandler from './fields/financing/OfferDealFinancingWithHandler';
import OfferDealFinancingNoHandler from './fields/financing/OfferDealFinancingNoHandler';
import { OfferDealLogisticsSimple } from './fields/logistics/OfferDealLogisticsSimple';

interface ITableRow {
  value: number | undefined,
  label: string,
  bold?: boolean
}

const AlreadyProducedDealSummary: React.FC = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const [isPopup, setIsPopup] = useState(false);

  const {
    id,
    type,
    productType,
    productCategory,
    documentCapabilities,
    packaging,
    coaUsed,
    customLabelPossible,
    productionLocation,
    productionDate,
    expirationDate,
    salesTerms,
    documents,
    productCharacteristics,
    deal,
    parentOffer,
    paymentTerms,
  } = ON_SPEC;

  const currency = deal?.currency || ON_SPEC.currency;

  // flags
  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isCreatedByAdmin = deal?.createdBy?.type === 'ADMIN';
  const isRfp = parentOffer?.type?.value === 'RFP';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags === END

  const offerTypeTitle = isRfp ? 'Proposal' : 'Offer';

  const openSpecsPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsPopup(true);
  };

  const vasPrices: ITableRow[] = [
    { value: deal?.netPrice, label: 'Product' },
    { value: deal?.shipping?.logisticPrice, label: 'Logistic' },
    { value: deal?.financing?.creditPrice, label: 'Financing' },
  ];

  const totalPriceRow: ITableRow = { value: deal?.price, label: 'TOTAL FOR BUYER', bold: true };

  const prices: ITableRow[] = [];

  if (!isSimpleOffer) {
    prices.push(...vasPrices);
  }

  prices.push(totalPriceRow);

  if (isToBeProduced) {
    prices.splice(1, 0, { value: deal?.packagingPrice, label: 'Packaging' });
  }

  const TableRow = (params: ITableRow) => {
    const { bold, label, value } = params;

    return (
      <tr className={clsx(bold && 'bold-text')}>
        <td>{`${label}: `}</td>
        <td><FormattedPrice value={value} suffix="/mt" prefix={currency} className="inline" /></td>
      </tr>
    );
  };

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Deal</div>
          {deal && (
            <>
              <DataItemPreview item={deal?.number} title="DEAL ID" />
              <DataItemPreview item={deal?.created} dateFormat="DD MMM YYYY" isDate title="DEAL DONE ON" />

              {isCreatedByAdmin && (
              <DataItemPreview item="OpenDairy" title="Added by" />
              )}

              <DataItemPreview item={deal?.volume} title="DEAL VOLUME" suffix="mt" />
              <FormattedPrice
                value={deal?.volume * deal?.price}
                prefix={currency}
                title="DEAL TOTAL PRICE"
              />
            </>
          )}

          <div className="page-section-title">Product</div>
          <DataItemPreview item={type?.label} title="OFFER TYPE" />
          <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
          <DataItemPreview item={productType} title="PRODUCT TYPE" />
          {!isSimpleOffer && (
            <>
              <div className="data-preview-row">
                <div className="label-text">{isRfp ? 'BUYER' : 'SELLER'} PRODUCT SPECIFICATION</div>
                <div><a href="##" onClick={(e) => openSpecsPopup(e)}>Specifications details</a></div>
              </div>
              {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
            </>
          )}
          {!isRfp && <DataItemPreview item={documentCapabilities} title="DOCUMENTS INCLUDED" />}
          {!isSimpleOffer && (
            <>
              <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />
              {isToBeProduced
                ? (<DataItemPreview item={deal?.shipping?.packaging} title="PACKAGING" />)
                : (<DataItemPreview item={packaging} title="PACKAGING" />)}
              <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />

              {!isToBeProduced && !isRfp && (
                <>
                  <DataItemPreview item={productionDate} dateFormat="DD MMM YYYY" isDate title="production date" />
                  <DataItemPreview item={expirationDate} dateFormat="DD MMM YYYY" isDate title="expiration date" />
                </>
              )}
            </>
          )}

          <DataItemPreview item={salesTerms} title="SALES TERMS" />

          {salesTerms?.value === 'MPC' && (
            <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
              <i className="icon-flag" />
              <span>MPC sales terms</span>
            </a>
          )}

          {documents && documents.SALES_TERMS && (
            <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />
          )}

          <br />

          <Link
            to={`/${isRfp ? 'rfp/proposals' : 'onspec/archived-offers'}/${id}`}
          >
            Show {offerTypeTitle.toLowerCase()} details
          </Link>
        </div>

        {/* VAS section */}
        <div className="page-column">

          <>
            <div className="page-section-title">Shipping & Logistics</div>
            {!isSimpleOffer && (
              <>
                {/* RFP, when Seller agreed on delivery terms */}
                <RfpDealLogisticsAgreed />
                {/* RFP and Offer, with handler */}
                <OfferDealLogisticsWithHandler />
                {/* RFP and Offer, no handler */}
                <OfferDealLogisticsNoHandler />
              </>
            )}

            {isSimpleOffer && (
              <OfferDealLogisticsSimple />
            )}
          </>

          <>
            <div className="page-section-title">Financing</div>
            {!isSimpleOffer && (
              <>
                {/* RFP, agreed on payment */}
                <RfpDealFinancingAgreed />
                {/* RFP and Offer, with handler */}
                <OfferDealFinancingWithHandler />
                {/* RFP and Offer, no handler */}
                <OfferDealFinancingNoHandler />
              </>
            )}

            {isSimpleOffer && (
              <DataItemPreview
                title="Seller payment terms"
                item={paymentTerms}
              />
            )}
          </>

        </div>
        {/* VAS section === END */}

        {deal && (
          <div className="page-column">
            <div className="page-section-title">Seller</div>
            <DataItemPreview item={deal.sellerOrg.orgName} title="SELLER" />
            <DataItemPreview item={getFullName(deal.seller.profile)} title="SELLER NAME" />
            <DataItemPreview item={deal.seller.profile.email} title="EMAIL" isMailLink />
            <DataItemPreview item={deal.seller.profile.phoneNumber} title="TELEPHONE" />

            <div className="page-section-title">Buyer</div>
            <DataItemPreview item={getFullName(deal.buyer.profile)} title="BUYER NAME" />
            <DataItemPreview item={deal.buyer.profile.email} title="EMAIL" isMailLink />
            <DataItemPreview item={deal.buyer.profile.phoneNumber} title="TELEPHONE" />
          </div>
        )}

        <div />

      </div>

      <div className="offer-auction-details prebid-info-section deal">
        <div>

          <div className="page-section-title">Deal pricing</div>

          <table>
            <tbody>
              {prices.map((p) => (
                <TableRow {...p} key={p.value} />
              ))}
              <tr>
                <td colSpan={2}>
                  <div><br /></div>
                  All prices are excluding any applicable taxes
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div className="page-section-title">This is what will happen next:</div>

        <div>
          The seller will contact you with the further steps.
          If you need any assistance from the OpenDairy team please don&apos;t hesitate to contact us.
        </div>
      </div>

      <br />
      <br />

      {/* ************************** product's haracteristics details **************************  */}
      {isPopup && productCharacteristics && (
        <ProductSpecsDetailsModal
          handleClose={setIsPopup}
          characteristics={productCharacteristics}
          coaUsed={coaUsed}
        />
      )}

    </>
  );
};

export default AlreadyProducedDealSummary;
