import clsx from 'clsx';
import React          from 'react';
import FormattedPrice from 'SHARED/components/Price';

export interface ITableRow {
  dataString?: string | null,
  value?: number | string,
  label: string,
  bold?: boolean,
  currency?: string | null,
  suffix?: string | null,
  byInfo?: string | null,
  icon?: string | null,
}

const PriceTableRow: React.FC<ITableRow> = ({
  bold, label, value, currency, suffix = '/mt', byInfo, dataString, icon,
}) => (
  <>
    {label && (
      <>
        <tr className={clsx(bold && 'bold-text')}>
          <td>{`${label}: `}</td>
          {!dataString && (
            <td>
              {value ? (
                <>
                  <FormattedPrice value={value} suffix={suffix} prefix={currency} className="inline" />
                  {byInfo && (
                    <span className="extra-description">
                      {` by ${byInfo}`}
                      {icon && <i className={`icon-${icon.toLowerCase()}`} />}
                    </span>
                  )}
                </>
              ) : '-'}
            </td>
          )}
          {dataString && <td>{dataString}</td>}
        </tr>
      </>
    )}
  </>
);

export default PriceTableRow;
