import React from 'react';
import { deleteBid } from 'SHARED/api/offers/delete/deleteBid';
import { useActions } from 'SHARED/redux/hooks/useActions';

interface Props {
  handleClose: (value: React.SetStateAction<boolean>) => void,
  offerId: number | string | undefined,
  bidId?: number | string | undefined,
  type: 'OFF_SPEC' | 'ON_SPEC'
}

const RemoveBidPopup: React.FC<Props> = ({ handleClose, offerId, type, bidId }) => {
  const { getOfferDetails, resetOffer } = useActions();

  const handleConfirmRemove = async () => {
    if (offerId) {
      await deleteBid({ offerId, type, bidId });

      handleClose(false);
      resetOffer();
      getOfferDetails({ id: offerId, offerType: type });
    }
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a
          href="##"
          className="popup-close"
          onClick={(e) => { handleClose(false); }}
          data-testing="popup-close-button"
        >
          close
        </a>

        <div>
          <h1>You are about to delete your bid</h1>

          <div>
            <p> Your bid on this offer or auction will be deleted and any negotiations are automatically ended.</p>
            <p>
              You can place a new bid at any time before the end of the auction or offer.
            </p>

          </div>

          <br />
          <br />

          <div>
            <button
              type="button"
              className="btn-primary"
              onClick={() => handleConfirmRemove()}
              data-testing="popup-submit-button"
            >
              Delete my bid
            </button>

            <button
              type="button"
              className="btn-secondary"
              onClick={(e) => { handleClose(false); }}
              data-testing="popup-cancel-button"
            >
              Back to offer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RemoveBidPopup;
