import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Preloader from 'SHARED/components/ThePreloader';
import { scrollToTop } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import routes from 'SHARED/types/routes';
import RFPSummary from 'pages/RFP/RfpSummary';

const RfpPreview: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { RFP } = useTypedSelector((state) => state.offer);
  const { createRFP } = useActions();

  PreventLossUnsavedData(true);

  useEffect(() => {
    if (!RFP.productCategory) {
      history.push(routes.rfp.create);
    }

    scrollToTop();
  }, []);

  const handlePlaceOrder = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    (async () => {
      if (await createRFP()) {
        history.push(routes.rfp.defaultList);
        // setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All RFPs </a>
      </div>
      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">Create an RFP</h1>

        <div className="attention-message inset">
          Great, you&apos;re almost done! Please review your RFP before you continue.
        </div>

        <RFPSummary mode="preview" />

        <div className="attention-message success inset no-margin">
          <div className="page-section-title">Place this RFP</div>

          <div>
            By placing this Request for Proposal you agree to the OpenDairy platform terms.
            {' '}
            <br />
            You do not have a legal obligation to buy from the highest bidder. Once placed, the RFP can not be changed.
          </div>

          <div className="actions-section">
            <a href="##" className="btn-primary" onClick={(e) => handlePlaceOrder(e)}>Place offer</a>
            <Link to={routes.rfp.create} className="btn-secondary">Change offer</Link>
            <a href={routes.rfp.defaultList} className="btn-secondary">Discard</a>
          </div>
        </div>

      </div>
    </>
  );
};

export default RfpPreview;
