import React, { FC } from 'react';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';

interface IProps {
  created: string | number
  message: string
}

const FeedbackPreview: FC<IProps> = ({ created, message }) => {
  const { userTimezone, userTimezoneAbbr } = useUserTimezone();

  return (
    <>
      <div className="compact-section">
        <h3 className="page-section-title compact">Your message about this proposal</h3>
        <p>
          You sent the following message to the seller. Remember: they cannot respond.
        </p>
      </div>

      <hr className="spacer gray" />

      <div className="compact-section">
        <p style={{ fontSize: 14, margin: 0 }}>
          {dateWithTimezone(created, userTimezone)} {userTimezoneAbbr}
        </p>

        <p>{renderMultiline(message)}</p>
      </div>
    </>
  );
};

export default FeedbackPreview;
