/* eslint-disable react/jsx-props-no-spreading */
import React          from 'react';
import { counterBid } from 'SHARED/helpers/texts';
import { Link }       from 'react-router-dom';
import routes         from 'SHARED/types/routes';

export type NonValidStatuses = 'NOT_VALID' | 'EXPIRED';

interface PropsBidNotValid {
  handleClose: (value: React.SetStateAction<NonValidStatuses | null>) => void,
  type: NonValidStatuses,
}

const BidNotValid: React.FC<PropsBidNotValid> = ({ handleClose, type }) => (
  <div className="popup">
    <div className="popup-overlay" />
    <div className="popup-body">
      <a href="##" className="popup-close" onClick={(e) => { handleClose(null); }}>
        close
      </a>

      {type === 'NOT_VALID' && (
        <div>
          <h1>{counterBid.notValidTitle}</h1>
          <div>
            <p>{counterBid.notValidText}</p>
            <p>{counterBid.notValidExplanation}</p>
            <a href="#close" onClick={() => { handleClose(null); }}>View the offer</a>
          </div>
        </div>
      )}

      {type === 'EXPIRED' && (
        <div>
          <h1>{counterBid.notValidTitle}</h1>
          <div>
            <p>{counterBid.expiredText}</p>
            <p>{counterBid.expiredExplanation}</p>
            <Link to={routes.onspec.list}> Please take a look at the other offers</Link>
          </div>
        </div>
      )}
    </div>
  </div>
);
export default BidNotValid;
