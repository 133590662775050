import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { putProposalFeedback } from 'SHARED/api/offers/put/putProposalFeedback';
import FormInput from 'SHARED/components/FormInput';
import Preloader from 'SHARED/components/ThePreloader';
import validationRules from 'SHARED/helpers/validation';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';

interface FormValues {
  message: string;
}

const FeedbackForm = () => {
  const { ON_SPEC: RfpProposal } = useTypedSelector((state) => state.offer);
  const { id, endDate, remainingVolume } = RfpProposal;
  const { getOfferDetails } = useActions();

  const methods = useForm<FormValues>();
  const { handleSubmit, reset } = methods;

  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(data: FormValues) {
    console.log(data.message?.trim());

    setIsLoading(true);
    putProposalFeedback({ id, endDate, remainingVolume, message: data.message?.trim() })
      .then((status) => {
        if (status === 'success') {
          reset();
          getOfferDetails({ id, offerType: 'ON_SPEC' });
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <Preloader isLoading={isLoading} />

      <div className="compact-section">
        <h3 className="page-section-title compact">Send a message to the seller</h3>

        <p>
          If this proposal is close to what you need, you can send a remark to the seller. Please note: you can only send one message and the seller cannot respond. They can create a new proposal if needed
        </p>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              name="message"
              fullWidth
              textarea
              rules={validationRules.required}
            />

            <button type="submit" disabled={!id || isLoading} className="btn-secondary">Send the message</button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default FeedbackForm;
