import React, { useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import FormattedPrice from 'SHARED/components/Price';
import Preloader from 'SHARED/components/ThePreloader';
import { Helmet } from 'react-helmet-async';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { scrollToTop } from 'SHARED/helpers/common';
import OfferCancellationInfo from 'SHARED/components/Offer/OfferCancellationInfo';
import OfferSummary from './OfferSummary';
import './offer-details.scss';

interface RouteParams {
  id: string,
  type: string
}

interface Props {
  isLoggedIn: boolean,
}

const OnspecOfferDetailsPage: React.FC<Props> = () => {
  const history = useHistory();
  const { id, type } = useParams<RouteParams>();

  const { ON_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  const getDealDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'ON_SPEC' });
    } catch (err) {
      console.log(err);
      history.push(`/onspec/${type}`);
    }
  };

  const { remainingVolume, latestBid, productCategory, productType, statusLabel, currency, parentOffer, type: offerType } = ON_SPEC;
  const isForRfp = parentOffer?.type?.value === 'RFP';
  const offerTitle = `${isForRfp ? `${offerType?.label}:` : ''} ${productCategory?.label} ${productType?.label}, ${remainingVolume} mt`;

  const isActiveParentOffer = parentOffer?.status?.value === 'ACTIVE';
  const proposalsUrl = `/rfp/${isActiveParentOffer ? 'offers' : 'archived-offers'}/${parentOffer?.id}`;

  useEffect(() => {
    scrollToTop();
    getDealDetails();
    return () => {
      resetOffer();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{offerTitle}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      {/* <div className="breadcrumbs">
        <Link to={`/onspec/${type}`}>&lt; All offers </Link>
      </div> */}

      <div className="breadcrumbs">
        {isForRfp
          ? <Link to={proposalsUrl}>&lt; Proposals </Link>
          : <Link to={`/onspec/${type}`}>&lt; All offers </Link>}
      </div>
      <div className="page-body is-logged-in preview-offer-page">

        {!isForRfp && <h1 className="page-title">{offerTitle}</h1>}

        {/* *********************** offer auction details ***********************  */}

        {!isForRfp && (
          <div className="offer-auction-details">
            <div>
              <DataItemPreview item={statusLabel} title="OFFER STATUS" />
            </div>

            {!isForRfp && (
            <div>
              <div className="label-text">OUR LAST BID | VOLUME</div>
              {latestBid
                ? (
                  <>
                    <FormattedPrice value={latestBid.totalPrice} prefix={currency} suffix="/mt" className="inline" />
                    <>{'| '}</>
                    <DataItemPreview title="" item={latestBid.volume} suffix="mt" className="inline" />
                  </>
                )
                : '-'}

            </div>
            )}
          </div>
        )}

        {!isForRfp && ON_SPEC.status === 'CANCELLED' && (
          <OfferCancellationInfo
            cancellationDate={ON_SPEC?.cancellationInfo?.date}
            cancellationReason={ON_SPEC.cancellationReason}
          />
        )}

        <OfferSummary />
      </div>
    </>
  );
};

export default OnspecOfferDetailsPage;
