import { Link, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import { BuyerPaymentTerm, Dictionary } from 'SHARED/types/offerTypes';
import routes from 'SHARED/types/routes';
import Select from 'SHARED/components/Select';
import { ProductCategory } from 'SHARED/types/specsTypes';
import texts, { tooltips } from 'SHARED/helpers/texts';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import FormInput from 'SHARED/components/FormInput';
import Checkboxes from 'SHARED/components/Checkboxes';
import validationRules from 'SHARED/helpers/validation';
import preparePreview from 'SHARED/helpers/prepareOfferData';
import { cloneDeep } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { clearProxyFields } from 'SHARED/helpers/common';
import clsx from 'clsx';
import { DevTool } from '@hookform/devtools';
import moment from 'moment-timezone';
import { isMobile } from 'react-device-detect';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';

const RfpCreate: React.FC = () => {
  const history = useHistory();

  const { me } = useTypedSelector((state) => state.users);
  const { RFP } = useTypedSelector((state) => state.offer);
  const { dictionaries, locations } = useTypedSelector((state) => state.dictionaries);
  const { categories, products, loading } = useTypedSelector((state) => state.specs);
  const { INCOTERM_BUYER, PAYMENT_TERMS } = dictionaries;

  const {
    getDictionaries,
    getLocations,
    saveOffer,
    resetOffer,
    getProductCategoriesList,
    getProducts,
  } = useActions();

  // 1 - waiting for org id
  // then get all data for offer creations
  useEffect(() => {
    if (me.orgId) {
      getDictionaries({ offerType: 'RFP', isBuyer: true });
      getLocations({ type: 'LOGISTIC', orgId: me.orgId });
      getProductCategoriesList(false);
    }
  }, [me]);

  const methods = useForm();
  const { formState: { isDirty, dirtyFields } } = methods;

  // 2 -   handling user discard offer creation
  // 2.1 - reset offer's state in redux
  // 2.2 - redirect back to offers list page
  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resetOffer();
    history.push(routes.rfp.defaultList);
  };

  const {
    productCategory,
    productType,
    product,
    departureFrom,
    departureTo,
    endDate,
    minVolume,
    maxVolume,
    packages,
    documentCapabilities,
    documents,
    incoterms: rfpIncoterms,
    deliveryLocation,
    paymentTerms,
    paymentOffset,
  } = RFP;

  const { watch, setValue } = methods;
  const choosedProductCategory: ProductCategory = watch('productCategory', productCategory);
  const choosedProductType: Dictionary = watch('productType', productType);
  const choosedProduct: Dictionary = watch('product', product);
  const choosedMinVolume: Dictionary = watch('minVolume', product);
  const choosedDepartureFrom = watch('departureFrom', departureFrom);
  const choosedDepartureTo = watch('departureTo', departureTo);
  const choosedIncoterms: Dictionary = watch('incoterms');
  const choosedPaymentTerms: BuyerPaymentTerm = watch('paymentTerms');

  const filteredBuyerIncoterms = INCOTERM_BUYER?.filter(
    (item) => locations.LOGISTIC.some(
      (location) => location.incoterms === item.value,
    ),
  ) || [];

  function filterLocationsByIncoterms() {
    if (!choosedIncoterms) return [];

    const filtered = locations.LOGISTIC.filter((location) => {
      const { incoterms } = location;
      return incoterms === choosedIncoterms.value;
    });

    return filtered;
  }

  useEffect(() => {
    if (choosedProductType && choosedProductType !== productType) {
      getProducts({ category: choosedProductCategory.value, type: choosedProductType.value });
      setValue('product', null);
    }
  }, [choosedProductType]);

  // if user choosed date from late than date to
  // date to need to be updated
  useEffect(() => {
    if (choosedDepartureFrom > choosedDepartureTo) {
      setValue('departureTo', choosedDepartureFrom);
    }
  }, [choosedDepartureFrom]);

  // clear relative dropdowns if product category has been changed
  useEffect(() => {
    if (choosedProductCategory !== productCategory) {
      setValue('productType', null);
      setValue('product', null);
    }
  }, [choosedProductCategory]);

  // different payment terms may have different payment offset list
  useEffect(() => {
    if (dirtyFields?.paymentTerms) {
      setValue('paymentOffset', null);
    }
  }, [choosedPaymentTerms]);

  // clear location if incoterms has been changed
  useEffect(() => {
    if (dirtyFields?.incoterms) {
      setValue('deliveryLocation', null);
    }
  },
  [choosedIncoterms]);

  // if form changed prevent closing browser tab
  // without prompting user
  PreventLossUnsavedData(isDirty);

  // ********************* SUBMIT sections *********************
  const onSubmit = (data: any) => {
    // get values from some fields
    const converted = {
      ...data,
      paymentOffset: data.paymentOffset?.value,
      documents: { ...documents, ...data.documents },
    };

    const prepared = preparePreview(cloneDeep(clearProxyFields(converted)), dictionaries);

    // console.log('data', data);
    // console.log('prepared', prepared);

    saveOffer({ offer: prepared, offerType: 'RFP' });

    // return true;

    history.push(routes.rfp.preview);
    return true;
  };

  return (
    <>
      <div className="breadcrumbs">
        <a href={routes.rfp.defaultList}>&lt; All RFPs </a>
      </div>

      <div className="page-body is-logged-in rfp-create-page">
        <Helmet>
          <title>Create an RFP</title>
        </Helmet>

        <Preloader isLoading={loading} />

        <div>
          <h1 className="page-title with-separator">Create an RFP</h1>

          <DevTool control={methods.control} placement="top-right" />

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="offer-wrapper">

                <h2 className="page-section-title">
                  Offer product
                </h2>

                <input type="hidden" {...methods.register('type')} value="RFP" />

                <Select
                  label="PRODUCT CATEGORY"
                  name="productCategory"
                  options={categories}
                  selected={productCategory}
                />

                {choosedProductCategory && (
                  <Select
                    label="PRODUCT TYPE"
                    name="productType"
                    options={choosedProductCategory.productTypes}
                    selected={productType}
                  />
                )}

                {choosedProductType && products && products.some((p) => p.active) && (
                  <Select
                    label="OUR PRODUCT SPECS"
                    name="product"
                    options={products.filter((p) => p.active).map((p) => ({ label: p.title, value: p.id.toString() }))}
                    selected={product}
                  />
                )}

                {choosedProductType && (!products || !products?.some((p) => p?.active)) && (
                  <div className="attention-message form-input">
                    You have no <Link to={routes.common.specifications}>Product Specification</Link> for this product type. You can create a new specification in your <Link to={routes.common.specifications}>organisational profile</Link>
                  </div>
                )}

                {choosedProduct && (
                  <>
                    <FormInput
                      name="description"
                      textarea
                      label="INSTRUCTION FOR THE SELLERS (OPTIONAL)"
                      value={RFP.description}
                    />

                    <FormFileInput
                      name="documents.SALES_TERMS"
                      label="ATTACH DOCUMENT"
                      defaultFiles={documents.SALES_TERMS}
                      acceptedFileTypes={['application/pdf']}
                    />

                    <Checkboxes
                      label="PACKAGING CAPABILITIES"
                      name="packages"
                      values={choosedProductCategory?.packagingOptions || []}
                      className="two-columns"
                      selected={packages}
                    />

                    <Checkboxes
                      label="DOCUMENT REQUIRED"
                      name="documentCapabilities"
                      values={dictionaries.DOCUMENT_TYPE}
                      className="two-columns"
                      tooltip={texts.tooltips.rfp.documents}
                      selected={documentCapabilities}
                    />

                    <h2 className="page-section-title">
                      Delivery
                    </h2>

                    <div className="dates-range">
                      <div className="label-text mb-05">
                        <span>DELIVERY PERIOD *</span>
                        {/* <Tooltip text={texts.tooltips.rfp.datesRange} /> */}
                      </div>

                      <div className="dates-range-wrapper">
                        <ZonedDatePicker
                          inline={isMobile}
                          name="departureFrom"
                          value={departureFrom}
                          minDate={new Date()}
                          showTimezone={false}
                        />
                        <ZonedDatePicker
                          inline={isMobile}
                          name="departureTo"
                          minDate={moment(choosedDepartureFrom).toDate()}
                          value={departureTo}
                          rules={validationRules.endDate(choosedDepartureFrom)}
                          showTimezone={false}
                        />
                      </div>
                    </div>

                    <Select
                      label="INCOTERMS"
                      name="incoterms"
                      // options={INCOTERM_BUYER}
                      options={filteredBuyerIncoterms}
                      selected={rfpIncoterms}
                      className="short"
                      tooltip="Some incoterms might not appear if you do not have a location provided for them. Contact OpenDairy to add another location"
                    />

                    {choosedIncoterms && filterLocationsByIncoterms().length === 0 && (
                      <div className="attention-message alert form-input">
                        Unfortunately you do not have a location provided for the selected incoterms. Select another incoterms or contact OpenDairy to add another location
                      </div>
                    )}

                    <Select
                      label="DELIVERY LOCATION"
                      name="deliveryLocation"
                      options={filterLocationsByIncoterms()}
                      selected={deliveryLocation}
                      disabled={filterLocationsByIncoterms().length === 0}
                    />

                    <h2 className="page-section-title">
                      Proposal volume
                    </h2>

                    <div className="inputs-range-wrapper">
                      <FormInput
                        name="minVolume"
                        label="MINIMUM VOLUME"
                        value={minVolume}
                        className="short"
                        type="number"
                        suffix="MT"
                        tooltip={tooltips.rfp.minVolume}
                        rules={validationRules.required}
                      />

                      <FormInput
                        name="maxVolume"
                        label="MAXIMUM VOLUME"
                        value={maxVolume}
                        className="short"
                        type="number"
                        suffix="MT"
                        rules={{ ...validationRules.required, min: { value: choosedMinVolume, message: 'Must be greater than min volume' } }} // TODO: add new rule
                      />
                    </div>

                    <div className={clsx(choosedPaymentTerms?.hasDayOffset && 'financing-row')}>
                      <div className="payment-term">
                        <Select
                          label="PAYMENT TERMS"
                          name="paymentTerms"
                          options={PAYMENT_TERMS}
                          selected={paymentTerms}
                        />
                      </div>

                      {choosedPaymentTerms?.hasDayOffset && (
                        <div className="days-offset">
                          <Select
                            label="NUMBER OF DAYS"
                            name="paymentOffset"
                            options={choosedPaymentTerms.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
                            selected={
                              paymentOffset
                                ? { value: `${paymentOffset}`, label: `${paymentOffset} days` }
                                : null
                            }
                            className="one-line-label"
                          />
                        </div>
                      )}
                    </div>

                    <h2 className="page-section-title">
                      RFP end date
                    </h2>

                    <ZonedDatePicker
                      label="RFP VALID UNTIL"
                      name="endDate"
                      showTimeInput
                      value={endDate}
                      dateFormat="dd MMMM yyyy HH:mm"
                      inline={isMobile}
                      rules={{
                        required: 'This field is mandatory',
                        ...validationRules.startDate(
                          moment(choosedDepartureFrom).toDate(),
                          'RFP end date must be before delivery range start date',
                        ),
                      }}
                    />

                    <button type="submit" disabled={!choosedProductCategory} className="btn-primary">Preview RFP</button>
                    <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Discard</button>
                  </>
                )}

              </div>
            </form>
          </FormProvider>

        </div>
      </div>
    </>
  );
};

export default RfpCreate;
