import React                from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import DataItemPreview      from 'SHARED/components/DataItemPreview';
import { Link }             from 'react-router-dom';

export interface IOffspecOfferSummaryProps {}

const OffspecDealSummary: React.FC<IOffspecOfferSummaryProps> = () => {
  const { OFF_SPEC } = useTypedSelector(
    (state) => state.offer,
  );

  const {
    title,
    description,
    documentCapabilities,
    packaging,
    incoterms,
    loadAddress,
    logisticLocation,
    loadOptions,
    loadDateFrom,
    loadDateTo,
    documents,
    latestBid,
    deal,
    salesTerms,
  } = OFF_SPEC;

  const SellerName = ():string => {
    if (!deal) return '';
    const {
      salutation, firstName, lastName, middleName,
    } = deal.seller.profile;

    return `${salutation || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''}`;
  };

  const BuyerName = ():string => {
    if (!deal) return '';
    const {
      salutation, firstName, middleName, lastName,
    } = deal.buyer.profile;

    return `${salutation || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''} `;
  };

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Product</div>
          <DataItemPreview item={title} title="OFFER TITLE" />
          <DataItemPreview item={description} title="DESCRIPTION" />
          {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
          <DataItemPreview item={documentCapabilities} title="DOCUMENTS INCLUDED" />
          <DataItemPreview item={packaging} title="PACKAGING" />
          <DataItemPreview item={salesTerms} title="SALES TERMS" />
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
          <br />
          <br />
          <Link type="button" to={`/offspec/archived-offers/${OFF_SPEC.id}`}>Show offer details</Link>
        </div>
        <div className="page-column">
          <div className="page-section-title">Shipping</div>
          <DataItemPreview item={incoterms} title="INCOTERMS" />
          <DataItemPreview datesRange={[loadDateFrom, loadDateTo]} title="PICK-UP DATE RANGE" />
          {!!loadAddress?.value && <DataItemPreview item={loadAddress} title="LOADING ADDRESS" />}
          {!!logisticLocation && <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />}
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />

          <br />
          <br />
          <div className="page-section-title">Deal</div>
          <DataItemPreview item={deal?.number} title="DEAL ID" />
          <DataItemPreview item={deal?.created} isDate title="DEAL DONE ON" />

        </div>
        <div className="page-column">
          <div className="page-section-title">Seller</div>

          <DataItemPreview item={deal?.sellerOrg.orgName} title="SELLER COMPANY" />
          <DataItemPreview item={deal?.sellerOrg.country} title="COUNTRY" />
          <DataItemPreview item={SellerName()} title="SELLER NAME" />
          <DataItemPreview item={deal?.seller?.profile?.email} title="EMAIL" isMailLink />
          <DataItemPreview item={deal?.seller?.profile?.phoneNumber} title="TELEPHONE" />

        </div>
        <div className="page-column">
          <div className="page-section-title">Buyer</div>
          <DataItemPreview item={deal?.buyerOrg?.orgName} title="BUYER COMPANY" />
          <DataItemPreview item={deal?.buyerOrg?.country} title="COUNTRY" />
          <DataItemPreview item={latestBid?.actionTime} isDate title="LATEST BID" />
          <DataItemPreview item={BuyerName()} title="BUYER NAME" />
          <DataItemPreview item={deal?.buyer?.profile?.email} isMailLink title="EMAIL" />
          <DataItemPreview item={deal?.buyer?.profile?.phoneNumber} title="TELEPHONE" />
        </div>
      </div>

      <div>
        <div className="page-section-title">This is what will happen next:</div>

        <div>
          <div>The seller will contact you soon to arrange the fulfilment of the deal. </div>
          <div>If the seller does not reach out to you within 2 working days, please reach out to the seller yourself. </div>
          <div>If you need any assistance from the OpenDairy team please don&apos;t hesitate to contact us. </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default OffspecDealSummary;
