import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const FIELDS = {
  PAYMENT_TERMS: 'form.paymentTerm',
  PAYMENT_OFFSET: 'form.paymentOffset',
  PRICE_ID: 'form.creditConditionId',
};

const RFPFinancingVas = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const {
    paymentTerms,
    paymentOffset,
    vasFinancing,
    version,
  } = ON_SPEC;

  const methods = useFormContext();
  const { setValue } = methods;

  useEffect(() => {
    if (version) {
      // ? for the field extended paymentTerms object needed
      // ? and it is present only in vasFinancing.availableBuyerPaymentTerms
      const paymentTermsMatch = vasFinancing?.availableBuyerPaymentTerms?.find(
        (item) => item.value === paymentTerms?.value,
      );

      setValue(FIELDS.PAYMENT_TERMS, paymentTermsMatch);
      setValue(FIELDS.PAYMENT_OFFSET, { label: `${paymentOffset}`, value: `${paymentOffset}` });
      setValue(FIELDS.PRICE_ID, 'OWN');
    }
  }, [version]);

  return (
    <>
      <div className="page-section-title">Financing</div>

      <div className="attention-message no-margin">
        This proposal complies with requested payment terms.
      </div>
      <div className="mb-2" />

      <DataItemPreview title="Requested payment terms" item={paymentTerms?.label} />
    </>
  );
};

export default RFPFinancingVas;
