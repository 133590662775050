import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { handleDateRange } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const FIELDS = {
  BUYER_INCOTERMS: 'form.buyerIncoterms',
  LOGISTIC_LOCATION: 'form.logisticLocationId',
  PRICE_ID: 'form.logisticPriceId',
};

const RFPDeliveryVas = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const {
    incoterms,
    logisticLocation,
    departureFrom,
    departureTo,
    version,
  } = ON_SPEC;

  const methods = useFormContext();
  const { setValue, watch } = methods;

  // values
  const incotermsValue = watch(FIELDS.BUYER_INCOTERMS);
  const logisticLocationValue = watch(FIELDS.LOGISTIC_LOCATION);
  const priceIdValue = watch(FIELDS.PRICE_ID);
  // values === END

  const delivery = handleDateRange({ from: departureFrom, to: departureTo });

  // ? Explanation:
  // ? these additional checks are needed to override default VAS logic
  // ? without it form will have values that will be wrong for this RFP case
  // INCOTERMS
  useEffect(() => {
    if (!version) return;

    if (incotermsValue !== incoterms?.value) {
      setValue(FIELDS.BUYER_INCOTERMS, incoterms);
    }
  }, [version, incotermsValue]);
  // INCOTERMS === END

  // LOGISTIC LOCATION
  useEffect(() => {
    if (!version) return;

    if (logisticLocationValue?.payload?.id !== logisticLocation?.id) {
      setValue(FIELDS.LOGISTIC_LOCATION, { payload: logisticLocation });
    }
  }, [version, logisticLocationValue]);
  // LOGISTIC LOCATION === END

  // PRICE ID
  useEffect(() => {
    if (!version) return;

    if (priceIdValue !== 'OWN') {
      setValue(FIELDS.PRICE_ID, 'OWN');
    }
  }, [version, priceIdValue]);
  // PRICE ID === END

  return (
    <>
      <div className="page-section-title">Logistics</div>

      <div className="attention-message no-margin">
        This proposal complies with requested delivery location and timeline.
      </div>
      <div className="mb-2" />

      <DataItemPreview
        title="Buyer incoterms"
        item={incoterms}
      />

      <DataItemPreview
        title="Logistic location"
        item={logisticLocation?.fullAddress}
      />

      <DataItemPreview title="Requested delivery" item={delivery} />
    </>
  );
};

export default RFPDeliveryVas;
