import React from 'react';
import Checkbox from 'SHARED/components/Checkboxes';
import { useForm, FormProvider } from 'react-hook-form';
import FormattedPrice from 'SHARED/components/Price';
import { useActions } from 'SHARED/redux/hooks/useActions';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { usePlaceMutation } from 'SHARED/api/_mutations/usePlaceBidMutation';
import Preloader from 'SHARED/components/ThePreloader';

interface Props {
  minPrice: number | null,
  bidVolume?: string,
  bidValue: string,
  id: string,
  handleClose: (value: React.SetStateAction<boolean>) => void,
  currency: string,
}

const PlaceBidPopup: React.FC<Props> = ({
  minPrice, bidValue, handleClose, id, bidVolume, currency,
}) => {
  const methods = useForm();
  const { getOfferDetails } = useActions();
  const { isLoading, mutateAsync: placeBidHandler } = usePlaceMutation();
  const agreeText = 'By placing this bid I commit to purchasing this product at the entered price and the binding result of this blind auction. The highest bid will be automatically confirmed with the seller.';

  const handleConfirmBid = async () => {
    const res = await placeBidHandler({ offerId: id, price: bidValue, volume: bidVolume });
    if (res?.isSuccess) {
      handleClose(false);
      getOfferDetails({ id, offerType: 'OFF_SPEC' });
    }
  };

  return (
    <>
      <Preloader isLoading={isLoading} />

      <div className="popup">
        <div className="popup-overlay" />
        <div className="popup-body">
          <a href="##" className="popup-close" onClick={(e) => { handleClose(false); }}>
            close
          </a>

          <div>
            <h1>You are about to place a bid!</h1>

            <div>
              During the time the auction is active you can change or remove your bid.
              If your bid is the winning bid it will be binding at the end of the auction.
            </div>

            <table className="bid-table">
              <thead>
                <tr>
                  <td>ASK PRICE</td>
                  <td>MY BID</td>
                  {bidVolume && <td>BID VOLUME</td>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <FormattedPrice suffix="/mt" prefix={currency} value={minPrice} />
                    </strong>
                  </td>
                  <td className="green">
                    <strong>
                      <FormattedPrice suffix="/mt" prefix={currency} value={bidValue} />
                    </strong>
                  </td>
                  {bidVolume && (
                  <td className="green">
                    <strong>
                      <DataItemPreview title="" item={bidVolume} suffix="mt" />
                    </strong>
                  </td>
                  )}
                </tr>

              </tbody>
            </table>

            <div>
              <FormProvider {...methods}>
                <form action="" onSubmit={methods.handleSubmit(handleConfirmBid)}>
                  <Checkbox
                    name="agree-bid"
                    values={[{ value: 1, label: agreeText }]}
                    rules={{ required: 'Please agree with the terms before submitting your bid.' }}
                  />
                  <div>
                    <button type="submit" className="btn-primary">Place my bid</button>
                    <button type="button" className="btn-secondary" onClick={() => { handleClose(false); }}>Back to offer</button>
                  </div>
                </form>

              </FormProvider>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default PlaceBidPopup;
