import { FinancingOption, Dictionary, DestinationLocation } from 'SHARED/types/offerTypes';
import { roundHalfUp } from './common';

type VasPrices = {
  perMT: number;
  total: number;
};

// ? regular flow calculations, with known netPrice
// === === OFFER SECTION === ===
// VAS LOGISTICS
interface ICalculateLogisticsParams {
  unitVolume: number | null;
  unitPrice: number | null;
  volume: number | null;
}
export const calculateLogistics = (params: ICalculateLogisticsParams): VasPrices => {
  const { unitVolume, unitPrice, volume } = params;
  const currentVolume = volume ? Number(volume) : 0;

  const noLogistics = { perMT: 0, total: 0 };

  if (!unitVolume || !unitPrice || !volume) return noLogistics;

  const containersAmount = Math.ceil(currentVolume / unitVolume);
  const logisticsPriceUnrounded = containersAmount * unitPrice;

  // final prices should be rounded to 2 decimal places
  // and total price should be calculated based on rounded price per MT
  // without it calculations might miss the mark by 1 cent
  const logisticsPricePerMT = roundHalfUp(logisticsPriceUnrounded / currentVolume);
  const logisticsPrice = roundHalfUp(logisticsPricePerMT * currentVolume);

  return {
    perMT: logisticsPricePerMT,
    total: logisticsPrice,
  };
};

export const findLogisticsLocation = (
  logisticsLocation: Dictionary | null,
  priceId: string | null,
) => {
  if (!logisticsLocation || !priceId || !logisticsLocation.payload || !logisticsLocation.payload.prices) {
    return null;
  }

  const { prices } = logisticsLocation.payload as DestinationLocation;

  if (prices) {
    const price = prices.find((p) => p.priceId === Number(priceId));
    return price;
  }

  return null;
};
// VAS LOGISTICS === END

// VAS FINANCING
interface ICalculateFinancingParams {
  volume: number;
  netPrice: number;
  totalLogisticsPrice: number | null;
  financingProvider: FinancingOption | null;
  packagingPrice: number | null;
}
export const calculateFinancing = (params: ICalculateFinancingParams): VasPrices => {
  const { volume, netPrice, totalLogisticsPrice = 0, financingProvider, packagingPrice } = params;
  const currentVolume = volume ? Number(volume) : 0;

  const financingYearLength = 360; // days
  const noFinancing = { perMT: 0, total: 0 };

  if (!financingProvider) {
    return noFinancing;
  }

  const {
    interestRate,
    creditTermDays,
    commissionPerDeal,
    commissionPerUnit,
  } = financingProvider;

  if (currentVolume && netPrice && financingProvider) {
    // offer price for product and logistics
    const netTotal = currentVolume * (netPrice + (packagingPrice || 0)) + (totalLogisticsPrice || 0);
    // financing commissions (per deal and per unit)
    const commisisonTotal = commissionPerDeal + commissionPerUnit * currentVolume;
    // interest on offer price and logistics
    const interestOnTotal = netTotal * (interestRate / 100) / financingYearLength * creditTermDays;
    // interest on commissions
    const interestOnCommission = commisisonTotal * (interestRate / 100) / financingYearLength * creditTermDays;

    // total financing cost
    const financingCostUnrounded = interestOnTotal + interestOnCommission + commisisonTotal;

    // final prices should be rounded to 2 decimal places
    // and total price should be calculated based on rounded price per MT
    // without it calculations might miss the mark by 1 cent
    const fincaingCostPerMT = Math.round(financingCostUnrounded / currentVolume * 100) / 100;
    const financingCost = fincaingCostPerMT * currentVolume;

    return {
      total: financingCost,
      perMT: fincaingCostPerMT,
    };

    // ? below is from confluence, but it's not correct for some reason
    // const effectiveInterestRate = (creditTermDays / 360 * interestRate / 100);
    // const financingPrice = ((netPrice + (totalLogisticsPrice || 0) + (packagingPrice || 0) + commissionPerDeal + commissionPerUnit * volume) * effectiveInterestRate) / volume;

    // const credit = roundPrice(financingPrice * volume);
    // const creditPerMT = roundPrice(financingPrice);

    // return {
    //   total: credit,
    //   perMT: creditPerMT,
    // };
  }

  return noFinancing;
};
// VAS FINANCING === END
// === === OFFER SECTION END === ===

interface ICalculateBidVasParams {
  volume: number;
  grossPrice: number;
  totalLogisticsPrice: number | null;
  financingProvider: FinancingOption | null;
  packagingPrice: number | null;
}

// ? bid flow calculations, with known grossPrice, but unknown netPrice
// === === PLACE A BID SECTION === ===
export const calculateBidNetPrice = (params: ICalculateBidVasParams): VasPrices => {
  const {
    volume = 0,
    grossPrice = 0,
    totalLogisticsPrice = 0,
    financingProvider,
    packagingPrice = 0,
  } = params;

  const {
    interestRate = 0,
    creditTermDays = 0,
    commissionPerDeal = 0,
    commissionPerUnit = 0,
  } = financingProvider || {};

  const noPrices = { perMT: 0, total: 0 };

  if (!volume || !grossPrice) {
    return noPrices;
  }

  if (!financingProvider) {
    const netPrice = grossPrice * volume - (totalLogisticsPrice || 0);
    const netPricePerMt = netPrice / volume - (packagingPrice || 0);

    return {
      perMT: netPricePerMt,
      total: netPrice,
    };
  }

  const effectiveInterestRate = (creditTermDays / 360 * interestRate / 100);
  const totalCreditPrice = grossPrice * volume;
  const creditWithoutInterestRate = totalCreditPrice / (1 + effectiveInterestRate);
  const totalWithoutFinancing = creditWithoutInterestRate - commissionPerDeal - (commissionPerUnit * volume);
  const financingPrice = grossPrice - totalWithoutFinancing;

  const netPrice = grossPrice - financingPrice - (totalLogisticsPrice || 0);
  const netPricePerMt = roundHalfUp((netPrice / volume - (packagingPrice || 0)));

  // console.clear();
  // console.log({
  //   effectiveInterestRate,
  //   totalCreditPrice,
  //   creditWithoutInterestRate,
  //   totalWithoutFinancing,
  //   financingPrice,
  //   netPrice,
  //   netPricePerMt,
  // });

  return {
    perMT: netPricePerMt,
    total: netPrice,
  };
};
// === === PLACE A BID SECTION END === ===
