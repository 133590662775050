import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import FormattedPrice from 'SHARED/components/Price';
import { Helmet } from 'react-helmet-async';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { scrollToTop } from 'SHARED/helpers/common';
import TwilioChat from 'SHARED/components/Chat/TwilioChat';
import OffspecDealSummary from './OffspecDealSummary';

interface RouteParams {
  id: string,
  type: string
}

interface Props {
  isLoggedIn: boolean,
}

const OffspecDeal: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const { OFF_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  const [chatOpened, setChatOpened] = useState<boolean>(false);

  const toggleChat = () => {
    setChatOpened(!chatOpened);
  };

  useEffect(() => {
    scrollToTop();
    fetchOfferDetails();
    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'OFF_SPEC', isDeal: true });
    } catch (err) {
      history.push('/offspec/offers');
    }
  };

  const {
    title, paymentTerms, deal,
  } = OFF_SPEC;

  return (
    <>
      <Helmet>
        <title>{OFF_SPEC?.title}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to="/offspec/deals">&lt; All deals </Link>
      </div>
      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">{`Deal: ${title}, ${deal?.volume} mt`}</h1>

        {/* *********************** offer auction details ***********************  */}

        <div className="offer-auction-details">
          <h2 className="page-section-title">Deal volume and price</h2>
          <div>
            <DataItemPreview item={paymentTerms} title="PAYMENT TERMS" />
            <DataItemPreview item={deal?.volume} title="VOLUME" suffix="mt" />
          </div>

          <div>
            <FormattedPrice value={deal?.price || 0} title="DEAL PRODUCT PRICE" suffix="/mt" prefix={deal?.currency} />
            <FormattedPrice value={deal?.amount || 0} title="TOTAL DEAL PRICE" prefix={deal?.currency} />
          </div>

        </div>

        <br />

        <OffspecDealSummary />
      </div>

      {/* TWILIO */}
      <button type="button" className="chat-toggler" onClick={toggleChat} title="Toggle chat">
        <i className="icon-chat" />
      </button>

      <TwilioChat visible={chatOpened} dealId={deal?.id} />
      {/* TWILIO === END */}
    </>
  );
};

export default OffspecDeal;
