import React from 'react';
import { Link } from 'react-router-dom';
import { OfferBid } from 'SHARED/types/bidTypes';

interface PropsSuccessAccept {
  handleClose: () => void,
  commitResult: OfferBid | null,
  isRfp?: boolean,
}

const SuccessAccept: React.FC<PropsSuccessAccept> = ({
  handleClose,
  commitResult,
  isRfp,
}) => {
  let dealUrl = '';
  if (commitResult) {
    dealUrl = `/onspec/deals/${commitResult?.dealId}`;

    if (isRfp) {
      dealUrl = `/rfp/deals/${commitResult?.dealId}`;
    }
  }

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a href="##" className="popup-close" onClick={handleClose}>
          close
        </a>

        <div>
          <h1>Congratulations!</h1>

          <div>
            <img src="/images/confetti.gif" alt="" className="fit-image" />
          </div>

          <div>
            You can now visit the deal page to chat with the seller and start exchanging documents. You can also find the seller’s contact details on this page.
          </div>

          <div>
            <br />
            <Link to={dealUrl}>View deal page</Link>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessAccept;
