import React, { useState } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { AcceptOfferPayload } from 'SHARED/types/offerTypes';
import { AcceptсCounterBidPayload, BidFormFields, BidPayload, OfferBid, PriceParams } from 'SHARED/types/bidTypes';
import prepareVasPayload from 'SHARED/helpers/prepareVasPayload';
import { FormProvider, useForm } from 'react-hook-form';
import axios from 'SHARED/helpers/axios';
import NewBidDetails from 'modals/NewBidDetails';
import SuccessAccept from 'modals/SuccessAccept';
import moment from 'moment';
import { BID_ENDPOINTS } from 'SHARED/helpers/common';
import { commonBidFields } from 'SHARED/helpers/prepareCommonData';
import { UpdateVasHandler } from 'types/offerBidTypes';
import BidNotValid, { NonValidStatuses } from 'modals/BidNotValid';
import { DevTool } from '@hookform/devtools';
import errorCodes from 'SHARED/helpers/codes';
import { showNotification } from 'SHARED/helpers/notifications';
import Preloader from 'SHARED/components/ThePreloader';
import AcceptOfferSummary from 'modals/AcceptOfferSummary';
import OfferNegotiation from './Partials/OfferNegotiation';
import AlreadyProducedFields from './AlreadyProducedFields';
import TobeProducedFields from './TobeProducedFields';
import OfferBottom from './Partials/OfferBottom';
import { Accept } from './Partials/OriginalOfferSection';
import './Partials/bid-section.scss';
import DealsList from './Partials/DealsList';
import VasSection from './Partials/vas/VasSection';
import ProposalFields from './ProposalFields';
import FeedbackSection from './Partials/proposalFeedback/FeedbackSection';
import { SimpleOfferFields } from './simple-offer-fields';

interface BidForm {
  form: BidFormFields
}

const OfferSummary: React.FC = () => {
  const { ON_SPEC, pricesLoading } = useTypedSelector((state) => state.offer);
  const { id, type, status, version, remainingVolume = 0, parentOffer } = ON_SPEC;

  const isActive = status === 'ACTIVE';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  const isProposal = type?.value === 'RFP_PROPOSAL';

  const methods = useForm<BidForm>();
  const { getOfferDetails, getOfferVas } = useActions();
  const getVasLogistics = (payload: PriceParams) => getOfferVas({ ...payload, type: 'logistics' });
  const getVasFinancing = (payload: PriceParams) => getOfferVas({ ...payload, type: 'financing' });
  const getPricesAndFinancing = (payload: PriceParams) => getOfferVas({ ...payload, type: 'all' });

  const { watch } = methods;
  const bidForm = watch('form');

  const [loading, setLoading] = useState(false);
  const [isBidPopup, setIsBidPopup] = useState(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const [isAcceptPopup, setIsAcceptPopup] = useState(false);
  const [isNotValidPopup, setIsNotValidPopup] = useState<null | NonValidStatuses>(null);
  const [bidDetails, setBidDetails] = useState<BidPayload | null>(null);
  const [offerPayload, setOfferPayload] = useState<AcceptOfferPayload | null>(null);
  const [counterBidPayload, setCounterBidPayload] = useState<AcceptсCounterBidPayload | null>(null);
  const [actionType, setActionType] = useState<'SUBMIT_BID' | 'ACCEPT_OFFER' | 'ACCEPT_COUNTER_OFFER' | null>(null);
  const [validationResult, setValidationResult] = useState<OfferBid | null>(null);

  const isRfp = parentOffer?.type?.value === 'RFP';

  const updateVas = (handlerType: UpdateVasHandler) => (isIncludeVas: boolean) => {
    const isCanUpdate = status === 'ACTIVE'
      && id
      && !pricesLoading
      && bidForm
      // required fields below
      && bidForm.buyerIncoterms
      && bidForm.logisticLocationId
      && bidForm.paymentTerm
      // payment term details
      // ? without this one we cant request vas when buyer's default payment term has day offset
      && (bidForm.paymentTerm.hasDayOffset ? bidForm.paymentOffset?.value : true);

    if (!isCanUpdate) return;

    const payload = prepareVasPayload({ form: bidForm, offer: ON_SPEC, isIncludeVas });

    // console.log('updateVas payload: ', payload);

    if (handlerType === 'logistics') {
      getVasLogistics(payload);
    }
    if (handlerType === 'financing') {
      getVasFinancing(payload);
    }
    if (handlerType === 'prices-and-financing') {
      getPricesAndFinancing(payload);
    }
  };

  // create HELPER or HOOK
  const showValidationMessage: (message: string) => void = (message) => {
    if (message === errorCodes.expiredOffer || message === errorCodes.outdatedOffer) {
      setIsNotValidPopup('EXPIRED');
    }
    if (message === errorCodes.notValidBid) {
      setIsNotValidPopup('NOT_VALID');
    }
  };

  // create HELPER or HOOK
  const handleNegotiationError = (error: any) => {
    if (error.status === 400) {
      showValidationMessage(error.data.message);
    } else {
      showNotification('COMMON_ERROR');
    }
  };

  // create HELPER or HOOK
  const closeSuccessAccept = () => {
    setIsSuccessPopup(false);
    getOfferDetails({ id, offerType: 'ON_SPEC' });
  };

  // ********************** VALIDATE bid before submitting **********************
  const validateBid = async (formPayload: BidForm) => { // TODO: refactor this ASAP
    const { form: { price, volume, message, validTo } } = formPayload;

    setLoading(true);
    setActionType('SUBMIT_BID');

    // set data for reuse in the counter bid popup
    const preparedBid = {
      ...commonBidFields(bidForm, id, version),
      price: parseFloat(price.replace(',', '.')),
      volume,
      message,
      validTo: parseInt(moment(validTo).format('x'), 10),
    };

    setBidDetails(preparedBid);

    try {
      const result = await axios.post(BID_ENDPOINTS.VALIDATE_BID, preparedBid);
      setValidationResult(result.data);
      result.data.autoDeal ? setIsAcceptPopup(true) : setIsBidPopup(true); // handling auto deal scenario
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  // ******************** SUBMIT bid ******************************
  const submitBid = async () => {
    setLoading(true);
    try {
      const result = await axios.post(BID_ENDPOINTS.SUBMIT_BID, bidDetails);
      setIsBidPopup(false);
      setIsAcceptPopup(false);
      setActionType(null);
      if (result.data.autoDeal) {
        setValidationResult(result.data);
        setIsSuccessPopup(true);
      } else {
        showNotification('BID_PLACED');
        getOfferDetails({ id, offerType: 'ON_SPEC' });
      }
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  // ******************** VALIDATE accepting offer  ********************
  const validateAcceptOffer: Accept = async (form: any) => {
    setLoading(true);
    setActionType('ACCEPT_OFFER');

    const preparedBid: AcceptOfferPayload = {
      ...commonBidFields(bidForm, id, version),
      // if offer is splittable set volume from form input
      // if NOt set original offer's volume amount
      volume: bidForm.acceptVolume || remainingVolume,
    };

    setOfferPayload(preparedBid);

    try {
      const result = await axios.post(BID_ENDPOINTS.VALIDATE_ACCEPT_OFFER, preparedBid);
      setIsAcceptPopup(true);
      setValidationResult(result.data);
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  // ******************** ACCEPT offer  ********************
  const acceptOffer = async () => {
    setLoading(true);

    try {
      const result = await axios.post(BID_ENDPOINTS.ACCEPT_OFFER, offerPayload);
      setValidationResult(result.data);
      setIsAcceptPopup(false);
      setIsSuccessPopup(true);
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
      setActionType(null);
    }
  };

  // *************** VALIDATE ACCEPT COUNTER OFFER  ***************
  const validateAcceptCounterBid = async (bidId: number) => {
    const payload: AcceptсCounterBidPayload = { bidId, offerVersion: version };
    setCounterBidPayload(payload);
    setLoading(true);
    setActionType('ACCEPT_COUNTER_OFFER');

    try {
      const result = await axios.post(BID_ENDPOINTS.VALIDATE_ACCEPT_BID, payload);
      await setValidationResult(result.data);
      setIsAcceptPopup(true);
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  // *************** ACCEPT COUNTER OFFER  ***************
  const acceptCounterBid = async () => {
    setLoading(true);

    try {
      const result = await axios.post(BID_ENDPOINTS.ACCEPT_BID, counterBidPayload);
      await setValidationResult(result.data);
      await setIsAcceptPopup(false);
      setIsSuccessPopup(true);
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  // choose submit action for accept popup
  //
  const acceptActionSubmit = () => {
    switch (actionType) {
      default: return submitBid();
      case 'ACCEPT_OFFER': return acceptOffer();
      case 'ACCEPT_COUNTER_OFFER': return acceptCounterBid();
    }
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <FormProvider {...methods}>
        <form action="" onSubmit={methods.handleSubmit(validateBid)}>
          {/* {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <pre className="rhf-devtools left">{JSON.stringify(methods.getValues(), null, 2)}</pre>} */}
          {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <DevTool control={methods.control} placement="top-right" />}

          <div className="page-columns">
            {type?.value === 'ALREADY_PRODUCED' && <AlreadyProducedFields />}
            {type?.value === 'TO_BE_PRODUCED' && <TobeProducedFields />}
            {type?.value === 'SIMPLE_ALREADY_PRODUCED' && <SimpleOfferFields />}
            {type?.value === 'RFP_PROPOSAL' && <ProposalFields />}

            {(isActive && !isSimpleOffer) && (
              <div className="page-column">
                <VasSection updateVas={updateVas} />
              </div>
            )}
          </div>

          <DealsList />

          <OfferNegotiation
            validateAcceptOffer={validateAcceptOffer}
            validateAcceptCounterBid={validateAcceptCounterBid}
          />
        </form>
      </FormProvider>

      {/* ***************** proposal feedback section *****************  */}
      {isRfp && <FeedbackSection />}

      <OfferBottom />

      {isBidPopup && validationResult && (
        <NewBidDetails
          bid={validationResult}
          remainingVolume={remainingVolume}
          handleClose={setIsBidPopup}
          submitBid={() => { submitBid(); }}
          watch={watch}
          isSimpleOffer={isSimpleOffer}
        />
      )}

      {isAcceptPopup && (
        <AcceptOfferSummary
          bid={validationResult}
          handleClose={setIsAcceptPopup}
          submitBid={() => acceptActionSubmit()}
          type={isProposal ? 'proposal' : 'offer'}
          isSimpleOffer={isSimpleOffer}
        />
      )}

      {isSuccessPopup && (
        <SuccessAccept
          handleClose={closeSuccessAccept}
          commitResult={validationResult}
          isRfp={isProposal}
        />
      )}

      {isNotValidPopup && (
        <BidNotValid
          handleClose={setIsNotValidPopup}
          type={isNotValidPopup}
        />
      )}

    </>
  );
};

export default OfferSummary;
