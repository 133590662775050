import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'SHARED/components/FormInput';
import PriceInput from 'SHARED/components/PriceInput';
import VolumeInput from 'SHARED/components/VolumeInput';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { preparePrice, preparePriceInputValue, roundPrice } from 'SHARED/helpers/common';
import texts, { tooltips } from 'SHARED/helpers/texts';
import validationRules from 'SHARED/helpers/validation';
import useCalculateOfferPrices from 'hooks/useCalculateOfferPrices';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import BidLivePreview from './BidLivePreview';

const BID_FIELDS = {
  price: 'form.price',
  volume: 'form.volume',
  validTo: 'form.validTo',
  message: 'form.message',
};

const DATE_FORMAT = 'dd MMMM yyyy HH:mm';

const ONE_HOUR = 60 * 60 * 1000;

const BidMaxDateMessage = 'You cannot set your bid expiry after the offer has expired. Please change your expiry date and time.';
const minMessage = 'This is a non-splittable offer, please bid for a volume equal or bigger than the offer volume.';

const PlaceBidForm = () => {
  const { setValue, formState: { errors }, watch } = useFormContext();

  // packaging price (TBP only)

  const bidPrice = watch(BID_FIELDS.price, 0);
  const bidVolume = watch(BID_FIELDS.volume, 0);

  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const {
    type,
    currency,
    remainingVolume = 0,
    splittable,
    endDate,
    personalOffer,
    price = 0,
  } = ON_SPEC;

  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isHavePersonalOffer = !!personalOffer;

  const newBidVolumeTooltipText = () => {
    switch (true) {
      case (!isToBeProduced && !splittable):
        return texts.buyerNewBid.volumeHint.apNonSplit;

      case (!isToBeProduced && splittable):
        return texts.buyerNewBid.volumeHint.apSplit;

      case (isToBeProduced && !splittable):
        return texts.buyerNewBid.volumeHint.tbpNonSplit;

      case (isToBeProduced && splittable):
        return texts.buyerNewBid.volumeHint.tbpSplit;

      default:
        return '';
    }
  };

  // calcuclate offer prices
  const {
    netPrice,
    packagingPrice,
    logisticPrice,
    creditPrice,
  } = useCalculateOfferPrices({ type: 'offer', volume: remainingVolume, price: price || 0, watch });
  // calcuclate offer prices === END

  const getOfferPrices = () => {
    const offerNetPrice = (isHavePersonalOffer ? personalOffer?.netPrice : netPrice) || 0;
    const offerPackagingPrice = (isHavePersonalOffer ? personalOffer?.packagingPrice : packagingPrice) || 0;
    const offerLogisticPrice = (isHavePersonalOffer ? personalOffer?.logisticPrice : logisticPrice) || 0;
    const offerCreditPrice = (isHavePersonalOffer ? personalOffer?.creditPrice : creditPrice) || 0;
    const offerTotalPrice = (netPrice + logisticPrice + creditPrice + packagingPrice) || 0;

    return {
      offerNetPrice,
      offerPackagingPrice,
      offerLogisticPrice,
      offerCreditPrice,
      offerTotalPrice,
    };
  };

  // extracted fields for better readability
  const prices = React.useMemo(() => getOfferPrices(), [
    personalOffer,
    netPrice,
    packagingPrice,
    logisticPrice,
    creditPrice,
  ]);

  useEffect(() => {
    const { offerTotalPrice } = prices;
    const prefillPrice = isHavePersonalOffer ? personalOffer?.totalPrice : offerTotalPrice;

    setValue(BID_FIELDS.price, preparePrice(prefillPrice));
  }, [prices]);

  // if buyer accepted some volume
  // remaining volume should be set to apropriate field
  useEffect(() => {
    const volume = isHavePersonalOffer ? personalOffer?.volume : remainingVolume;
    setValue(BID_FIELDS.volume, roundPrice(volume));
  }, [remainingVolume]);

  return (
    <div className="offer-auction-details bid-section" data-testing="bid-form">
      <div>
        <h2>Place a bid</h2>
        <div>{splittable ? texts.offerTexts.common.placeBid : texts.offerTexts.common.placeBidNonSplittable}</div>
      </div>

      <div>
        <div className="bid-form-layout">
          <div className="bid-form-fields">
            {/* disable volume for ALREADY PRODUCED and not splittable offers  */}
            {/* input has limitation for max value only for ALREADY PRODUCED offers  */}
            <VolumeInput
              name={BID_FIELDS.volume}
              value={remainingVolume}
              disabled={!isToBeProduced && !splittable}
              hasMaxValue={!isToBeProduced}
              tooltipText={newBidVolumeTooltipText()}
              rules={{
                required: true,
                min: splittable ? false : { value: remainingVolume, message: minMessage },
              }}
              testingName="bid-volume-input"
            />

            <PriceInput
              name={BID_FIELDS.price}
              label="Bidding price"
              value={null}
              tooltip={tooltips.bid}
              currency={currency === 'USD' ? '$' : '€'}
              testingName="bid-price-input"
            />

            <ZonedDatePicker
              label="Bid valid until"
              name={BID_FIELDS.validTo}
              showTimeInput
              value={endDate - ONE_HOUR}
              rules={validationRules.startDate(new Date(endDate), BidMaxDateMessage)}
              dateFormat={DATE_FORMAT}
            />

            <FormInput
              name={BID_FIELDS.message}
              textarea
              label="Message for the seller"
              testingName="bid-message-input"
            />

            {errors.logisticPrice && <div className="error-message separate">Please choose you logistic handler</div>}
          </div>

          <div>
            <BidLivePreview
              bidVolume={bidVolume}
              bidPrice={preparePriceInputValue(bidPrice)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="btn-primary"
            data-testing="bid-submit-button"
          >
            Review your counter bid
          </button>
        </div>
      </div>

    </div>
  );
};

export default PlaceBidForm;
