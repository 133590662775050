import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import React from 'react';
import FeedbackForm from './FeedbackForm';
import FeedbackPreview from './FeedbackPreview';

const FeedbackSection = () => {
  const { ON_SPEC: RfpProposal } = useTypedSelector((state) => state.offer);
  const { feedback, status } = RfpProposal;

  const isActive = status === 'ACTIVE';

  return (
    <section className="inset-section yellow">
      {!feedback && isActive && <FeedbackForm />}

      {!!feedback && (
        <FeedbackPreview
          created={feedback.created}
          message={feedback.message}
        />
      )}
    </section>
  );
};

export default FeedbackSection;
