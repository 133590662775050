import React           from 'react';
import { useFormContext } from 'react-hook-form';
import FormattedPrice  from 'SHARED/components/Price';
import validationRules from 'SHARED/helpers/validation';
import { Price }       from 'SHARED/types/offerTypes';

export interface Props {
  priceItem: Price,
  methods?: any,
  caption?: string,
  name?: string,
  iconName?: string,
  onChange?: (name: string) => void,
  currency?: string,
}

const PriceOption: React.FC<Props> = (props) => {
  const {
    priceItem,
    caption,
    iconName,
    name = 'form.destinationPrice',
    onChange = () => {
      console.log('onchange');
    },
    currency = 'EUR',
  } = props;
  // TODO: for now option should use offer currency, because for now all financing prices are in USD
  // const { partner, priceId, currency, price, id } = priceItem;

  const { partner, priceId, price, id } = priceItem;

  const { register } = useFormContext();

  const priceField = register(name, validationRules.required);

  return (

    <div className="custominput">
      <input
        type="radio"
        id={`${name}-${id || priceId}`}
        value={id || priceId}
        {...priceField}
        onChange={(value) => { priceField.onChange(value); onChange(name); }}
      />

      <label className="destinationLocation" htmlFor={`${name}-${id || priceId}`} title={`ID:${priceId || id}`}>
        <span>
          {partner.label}
          {/* {priceId}
          {id} */}
        </span>
        {priceItem.price !== 0 && (
        <strong>
          {iconName && <i className={`icon-${iconName.toLowerCase()}`} />}
          <span className="prefix-nbsp">+</span>
          <FormattedPrice
            suffix="/mt"
            className="inline"
            prefix={currency}
            value={price}
          />
        </strong>
        )}

      </label>
      {caption && <div className="price-caption">{caption}</div>}
    </div>
  );
};

export default PriceOption;
