import { axios_Core } from 'SHARED/helpers/axios';
import notification, { showNotification } from 'SHARED/helpers/notifications';

interface IPlaceBidArgs {
  offerId: number | string,
  price: string,
  volume?: string | number,
}

export const postPlaceBid = async (args: IPlaceBidArgs) => {
  const { offerId, price, volume } = args;

  // ? when volume is not modified its a number
  // ? hereby it cannot be used with string methods like replace
  const isVolumeString = typeof volume === 'string';
  const volumeValue = volume ? `${volume}` : '0';

  try {
    const formattedPrice = parseFloat(price.replace(',', '.'));
    const formattedVolume = isVolumeString ? parseFloat(volumeValue.replace(',', '.')) : volumeValue;

    const requestParams = volume ? {
      volume: formattedVolume,
    } : {};

    // ? not sure why there is `off-spec` in the url
    // ? but it works...
    await axios_Core.post(
      `/v1/offers/off-spec/${offerId}/bids`,
      { value: formattedPrice }, // data
      { params: requestParams }, // config
    );

    showNotification('BID_PLACED');

    return { isSuccess: true };
  } catch (error) {
    console.log('placeBid error', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not place bid...',
    });

    return { isSuccess: false };
  }
};
