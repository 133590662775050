import React from 'react';

interface IProps {
  text: string
  mailto?: string;
  className?: string;
}

/**
 * Mail link (inline) component
 * @prop {string} text - text to display
 * @prop {string} mailto - optional email, by default 'support@opendairy.com'
 * @prop {string} className - css class
 */

const MailLink: React.FC<IProps> = ({
  text,
  mailto = 'support@opendairy.com',
  className,
}) => (
  <a
    href={`mailto:${mailto}`}
    rel="noreferrer noopener"
    className={className}
  >
    {text}
  </a>
);

export default MailLink;
