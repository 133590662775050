import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams, Switch, Route } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { INavTabItem } from 'SHARED/types/common';
import routes from 'SHARED/types/routes';
import NavTabs from 'SHARED/components/NavTabs';
import FormattedPrice from 'SHARED/components/Price';
import Preloader from 'SHARED/components/ThePreloader';
import DocumentsExchange from 'SHARED/documentExchange/DocumentsExchange';
import { Helmet } from 'react-helmet-async';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import TwilioChat from 'SHARED/components/Chat/TwilioChat';
import { scrollToTop } from 'SHARED/helpers/common';
import AlreadyProducedDealSummary from './OnspecDealSummary';

interface RouteParams {
  dealId: string,
  type: string
}

interface Props {
  isLoggedIn: boolean,
}

const AlreadyProducedDeal: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();
  const { dealId } = useParams<RouteParams>();
  const { ON_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  useEffect(() => {
    scrollToTop();
    getDealDetails();
    return () => {
      resetOffer();
    };
  }, []);

  const getDealDetails = async () => {
    try {
      getOfferDetails({ id: dealId, offerType: 'ON_SPEC', isDeal: true });
    } catch (err) {
      console.log(err);
      history.push('/onspec/deals');
    }
  };

  const [chatOpened, setChatOpened] = useState<boolean>(false);

  const toggleChat = () => {
    setChatOpened(!chatOpened);
  };

  const { productCategory, productType, deal, departureFrom, departureTo, parentOffer } = ON_SPEC;

  const isRfp = parentOffer?.type?.value === 'RFP';
  const category = isRfp ? 'rfp' : 'onspec';
  const backUrl = isRfp ? routes.rfp.deals : routes.onspec.deals;

  const tabs: INavTabItem[] = [
    { label: 'Deal details', link: `/${category}/deals/${dealId}` },
    { label: 'Documents', link: `/${category}/deals/${dealId}/documents` },
  ];

  if (!deal) return (<Preloader isLoading />);

  return (
    <>
      <Helmet>
        <title>{`${productCategory?.label} ${productType?.label}`}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to={backUrl}>&lt; All deals </Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">
          {`Deal for ${productCategory?.label} ${productType?.label}, ${deal?.volume} mt`}
        </h1>

        {/* DEAL SHORT DETAILS */}
        <div className="offer-auction-details">
          <FormattedPrice value={deal.price * deal.volume} prefix={deal?.currency} title="TOTAL DEAL PRICE" />
          <DataItemPreview datesRange={[departureFrom, departureTo]} title="DEPARTURE" />
          <DataItemPreview item={`${deal?.sellerOrg?.orgName}, ${deal?.sellerOrg?.country}`} title="SELLER" />
        </div>
        {/* DEAL SHORT DETAILS === END */}

        <NavTabs items={tabs} />

        {/* TABS CONTENT */}
        <Switch>
          <Route exact path={[routes.onspec.dealDetail, routes.rfp.dealDetail]}>
            <AlreadyProducedDealSummary />
          </Route>

          <Route exact path={[routes.onspec.dealDetail_documents, routes.rfp.dealDetail_documents]}>
            <DocumentsExchange />
          </Route>
        </Switch>
        {/* TABS CONTENT === END */}

        {/* TWILLIO */}
        <button type="button" className="chat-toggler" onClick={toggleChat} title="Toggle chat">
          <i className="icon-chat" />
        </button>

        <TwilioChat visible={chatOpened} dealId={dealId} />
        {/* TWILLIO === END */}
      </div>
    </>
  );
};

export default AlreadyProducedDeal;
