import clsx from 'clsx';
import React, { FC } from 'react';

interface IProps {
  colSpan?: number;
  spacerClass?: string;
  decoClass?: string;
}

const TableSpacer: FC<IProps> = ({
  colSpan = 2,
  spacerClass,
  decoClass,
}) => {
  return (
    <tr className={clsx('spacer', spacerClass)}>
      <td colSpan={colSpan} className={clsx('deco', decoClass)} />
    </tr>
  );
};

export default TableSpacer;
