import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import MailLink from 'SHARED/components/MailLink';
import FormattedPrice from 'SHARED/components/Price';
import FormSelect from 'SHARED/components/Select';
import { handleDate, handleDateRange, MpcDocLink } from 'SHARED/helpers/common';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import { offerLabels } from 'SHARED/helpers/texts';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

export const SimpleOfferFields = () => {
  const { ON_SPEC: offer } = useTypedSelector((state) => state.offer);

  // flags
  const isMPC = offer?.salesTerms?.value === 'MPC';
  // flags === end

  // data
  const departure = offer?.departurePeriod?.value === 'MONTH'
    ? handleDate({ date: offer.departureFrom, format: 'MMM YYYY' })
    : handleDateRange({ from: offer.departureFrom, to: offer.departureTo });
  // data === end

  return (
    <>
      <div className="details-grid col-3">
        <div className="details-col">
          <h3 className="page-section-title">Product</h3>
          <DataItemPreview
            title="Remarks for the buyer"
            multilineText={offer.description}
            multiline
          />
          <DataItemPreview title="Offer type" item={offer.type} />
          <DataItemPreview title="Product category" item={offer.productCategory} />
          <DataItemPreview title="Product type" item={offer.productType} />
          <DataItemPreview
            title={offerLabels.alreadyProduced.documents}
            item={offer.documentCapabilities}
          />
          <FormSelect
            label="PACKAGING"
            name="form.packaging"
            options={offer.packages || []}
            selected={offer?.packages ? offer.packages[0] : null}
          />
          <DataItemPreview item={offer.salesTerms} title="SALES TERMS" />
          {isMPC && (
            <DataItemPreview
              title="SALES TERMS DOCUMENT"
              link={{ url: MpcDocLink, displayName: 'MPC sales terms' }}
            />
          )}
          {!isMPC && offer?.documents?.SALES_TERMS && (
            <DataItemPreview
              title="SALES TERMS DOCUMENT"
              file={offer.documents.SALES_TERMS[0]}
            />
          )}
        </div>

        <div className="details-col">
          <h3 className="page-section-title">Seller</h3>
          <DataItemPreview
            title="Seller company"
            item={offer.ownerOrg?.orgName}
            icon={offer.anonymous ? 'icon-eye-slash' : ''}
          />

          <h3 className="page-section-title">Shipping</h3>
          <DataItemPreview title="Departure" item={departure} />

          <h3 className="page-section-title">Volume and price</h3>
          <DataItemPreview
            title="Volume"
            item={offer.remainingVolume}
            suffix={offer.volumeUnits.label}
          />
          <DataItemPreview
            title="SPLITTABLE"
            icon={offer.splittable ? 'icon-splittable' : ''}
            item={offer.splittable ? 'Yes' : 'no'}
          />
          <FormattedPrice
            title="PRICE"
            value={offer.price}
            prefix={offer.currency}
            suffix="/mt"
          />

          <h3 className="page-section-title">Offer expiration</h3>
          <DataItemPreview title="Offer end date" item={offer.endDate} isDate />
        </div>

        <div className="details-col">
          <h3 className="page-section-title">Logistics</h3>
          <DataItemPreview
            title="Seller incoterms"
          >
            {renderMultiline(`${offer.incoterms?.label}, ${offer.logisticLocationNote}`)}
            <div className="attention-message no-margin">
              <MailLink text="Contact OpenDairy" /> if you need assistance with logistics
            </div>
          </DataItemPreview>

          <h3 className="page-section-title">Financing</h3>
          <DataItemPreview
            title="Seller payment terms"
          >
            {offer.paymentTerms?.label}
          </DataItemPreview>

          <div className="attention-message no-margin">
            <MailLink text="Contact OpenDairy" /> if you need assistance with financing
          </div>
        </div>
      </div>
    </>
  );
};
