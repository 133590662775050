/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import validationRules from 'SHARED/helpers/validation';
import { scrollToTop } from 'SHARED/helpers/common';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import { useForm, FormProvider } from 'react-hook-form';
import AddBidModal from 'modals/AddBidModal';
import RemoveBidModal from 'modals/RemoveBidModal';
import FormattedPrice from 'SHARED/components/Price';
import PriceInput from 'SHARED/components/PriceInput';
import { OfferStatuses } from 'SHARED/types/offerTypes';
import DataItemPreview from 'SHARED/components/DataItemPreview';
// import { DevTool } from '@hookform/devtools';
import FormInput from 'SHARED/components/FormInput';
import OffspecOfferSummary from './OffspecOfferSummary';
import AuctionLatestBidSection from './AuctionLatestBidSection';

interface RouteParams {
  id: string,
  type: string,
}

interface Props {
  isLoggedIn: boolean,
}

const OffspecOfferPage: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();
  const [bidVolume, setBidVolume] = useState('');
  const [bidValue, setBidValue] = useState('');
  const [isPopup, setIsPopup] = useState(false);
  const [isRemovePopup, setIsRemovePopup] = useState(false);

  const { id, type } = useParams<RouteParams>();

  const { OFF_SPEC, loading } = useTypedSelector((state) => state.offer);

  const methods = useForm();

  const { getOfferDetails, resetOffer } = useActions();

  useEffect(() => {
    scrollToTop();
    fetchOfferDetails();
    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'OFF_SPEC' });
    } catch (err) {
      history.push('/offspec/offers');
    }
  };

  const handleDeleteBid = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsRemovePopup(true);
    setIsPopup(false);
  };

  const handleBidSubmit = (data: { bidValue: string, bidVolume: string }) => {
    // console.log(data);
    // return;

    setBidValue(data.bidValue);
    if (OFF_SPEC.splittable) {
      setBidVolume(data.bidVolume);
    }
    setIsPopup(true);
    setIsRemovePopup(false);
  };

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     history.push('/login');
  //   }
  // }, [isLoggedIn, history]);

  const {
    title,
    totalVolume = 0,
    latestBid,
    currency,
    type: offerType,
    splittable,
    minBidVolume = 0.2,
    status,
  } = OFF_SPEC;

  return (
    <>
      <Preloader isLoading={loading} />

      {/* make a bid popup */}
      {isPopup && (
        <AddBidModal
          handleClose={setIsPopup}
          minPrice={OFF_SPEC.price}
          bidVolume={bidVolume}
          bidValue={bidValue}
          id={id}
          currency={currency}
        />
      )}
      {isRemovePopup && <RemoveBidModal handleClose={setIsRemovePopup} type="OFF_SPEC" offerId={id} />}

      <div className="breadcrumbs">
        <Link to={`/offspec/${type}`}>&lt; All auctions </Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">
          {`${offerType?.label ?? 'Auction'}: ${title},`}
          <span className="text-nowrap">
            {!!splittable && <i className="icon-splittable" style={{ fontSize: 20 }} />}
            {`${totalVolume} mt`}
          </span>
        </h1>

        {/* *********************** offer auction details ***********************  */}

        <div className="offer-auction-details">
          <div>
            <DataItemPreview item={OfferStatuses[OFF_SPEC?.status]} title="AUCTION STATUS" />
          </div>

          {/* https://brain-agency.atlassian.net/wiki/spaces/OP/pages/2742485094/Buyer+views+cancelled+Off-Spec+offer */}
          {OFF_SPEC?.status === 'CANCELLED'
            && <div><DataItemPreview item={OFF_SPEC?.cancellationReason} title="REASON FOR CANCEL" /></div>}

          {/* https://brain-agency.atlassian.net/wiki/spaces/OP/pages/2742485182/Buyer+views+his+lost+Off-spec+offers+after+Auction */}
          {OFF_SPEC?.status === 'ARCHIVED'
            && (
              <div>
                <div className="data-preview-row">
                  <div className="label-text">WINNING BID</div>
                  <FormattedPrice value={OFF_SPEC?.winningBid?.value || 0} title="" suffix="/mt" prefix="EUR" className="inline" />
                  <DataItemPreview isDate item={OFF_SPEC?.winningBid?.actionTime} title="" className="inline" prefix=" (" suffix=")" />
                </div>
              </div>
            )}

        </div>

        <br />

        <OffspecOfferSummary />

        {/* TODO: #decomposition needed */}
        {latestBid && latestBid.active && (
          <AuctionLatestBidSection
            bid={latestBid}
            onDeleteBid={(e) => handleDeleteBid(e)}
            auctionStatus={status}
          />
        )}

        {/* TODO: #decomposition needed  */}
        {OFF_SPEC?.status === 'ACTIVE' && (
          <div className="offer-auction-details">
            <div>
              <h1 className="page-section-title">Place a new bid</h1>

              <div>
                You can bid any price but the seller is only obligated to sell if your bid is above the asking price.
              </div>

              <br />

              <FormProvider {...methods}>
                {/* {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <DevTool control={methods.control} placement="top-right" />} */}

                <form onSubmit={methods.handleSubmit(handleBidSubmit)}>
                  {splittable && (
                    <>
                      <FormInput
                        name="bidVolume"
                        label="BID VOLUME"
                        value={OFF_SPEC.totalVolume}
                        className="short"
                        type="number"
                        suffix="MT"
                        rules={validationRules.minBidVolume(minBidVolume)}
                      />

                      <div className="minimal-price">
                        <span>Min volume:</span>
                        <DataItemPreview item={OFF_SPEC.minBidVolume || 0} title="" suffix="mt" />
                      </div>
                    </>
                  )}

                  <PriceInput
                    name="bidValue"
                    label="BID PRICE"
                    value=""
                    tooltip=""
                    currency={currency === 'USD' ? '$' : '€'}
                  />
                  <div className="minimal-price">
                    <span>Ask price:</span>
                    <FormattedPrice value={OFF_SPEC.price || 0} title="" suffix="/mt" prefix={currency} />
                  </div>

                  <br />

                  <button type="submit" className="btn-primary">Review my bid</button>
                  <br />
                  <br />
                </form>
              </FormProvider>

            </div>

          </div>
        )}

      </div>
    </>
  );
};

export default OffspecOfferPage;
