import React, { useEffect, useState } from 'react';
import Select from 'SHARED/components/Select';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useFormContext } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { BuyerPaymentTerm } from 'SHARED/types/offerTypes';
import Preloader from 'SHARED/components/ThePreloader';
import { convertToDictionary } from 'SHARED/helpers/common';
import clsx from 'clsx';
import prepareFinancingDetails from 'SHARED/helpers/prepareFinancingDetails';
import PriceOption from '../PriceOption';
import NoFinancingOptionsMsg from '../messages/NoFinancingOptionsMsg';
import NoFinancingNeededMsg from '../messages/NoFinancingNeededMsg';
import FinancingNotExistMsg from '../messages/FinancingNotExistMsg';
import RFPFinancingVas from './RFPFinancingVas';

interface Props {
  updatePrices: (isIncludeVas: boolean) => void
}

const FINANCING_FIELDS = {
  term: 'form.paymentTerm',
  offset: 'form.paymentOffset',
  credit: 'form.creditConditionId',
};

const OWN_FINANCING = {
  id: 'OWN',
  price: 0,
  partner: {
    label: 'I’ll arrange financing myself',
    value: 'OWN',
  },
  caption: 'seller payment terms apply',
};

const OfferFinancing: React.FC<Props> = ({ updatePrices }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { watch, setValue } = useFormContext();

  const { vasFinancing, currency, agreedOnPayment, type } = ON_SPEC;
  const isProposal = type?.value === 'RFP_PROPOSAL';
  const isShouldHideFinancing = isProposal && agreedOnPayment;

  const { financingExist, financingNeeded, financingAvailable } = vasFinancing || {};

  const choosedpaymentTerm: BuyerPaymentTerm = watch(FINANCING_FIELDS.term);
  const choosedCredit = watch(FINANCING_FIELDS.credit);

  const [creditOptions, setCreditOptions] = useState<any>([]); // TODO: fix this

  const getCreditOptionCaption = (item: any) => {
    if (item.caption) {
      return item.caption;
    }

    if (item.price) {
      return prepareFinancingDetails({
        currency,
        creditInterestRate: item.interestRate,
        commissionPerUnit: item.commissionPerUnit,
        commissionPerDeal: item.commissionPerDeal,
      });
    }

    return '';
  };

  useEffect(() => {
    if (vasFinancing) {
      const newCreditOptions = [...vasFinancing?.options || [], OWN_FINANCING];
      setCreditOptions(newCreditOptions);

      if (!choosedCredit) {
        const firstOption = newCreditOptions[0].id;
        const defValue = typeof firstOption === 'string' ? firstOption : firstOption.toString();

        setValue(FINANCING_FIELDS.credit, defValue);
      }
    }
  }, [vasFinancing]);

  if (!vasFinancing) return <Preloader isLoading />;

  const { availableBuyerPaymentTerms, buyerPaymentTerms, buyerPaymentOffset } = vasFinancing;

  return (
    <>

      {/* Regular offer financing (offer / proposal) */}
      {!isShouldHideFinancing && (
        <>
          <div className="page-section-title">Financing</div>
          <DataItemPreview
            item={vasFinancing.sellerPaymentTerms}
            title="SELLER PAYMENT TERMS"
          />

          {!financingExist && <FinancingNotExistMsg />}

          {/* <div> */}
          <div className={clsx(!financingExist && 'visually-hidden')}>
            <div className="financing-row">
              <div className="payment-term">
                <Select
                  label="BUYER PAYMENT TERMS"
                  name={FINANCING_FIELDS.term}
                  options={availableBuyerPaymentTerms || []}
                  selected={buyerPaymentTerms}
                />
              </div>

              {choosedpaymentTerm?.hasDayOffset && (
              <div className="days-offset">
                <Select
                  label="NUMBER OF DAYS"
                  name={FINANCING_FIELDS.offset}
                  options={buyerPaymentTerms.offsetDays.map((d) => convertToDictionary(d))}
                  selected={convertToDictionary(buyerPaymentOffset)}
                />
              </div>
              )}
            </div>

            {!financingNeeded && <NoFinancingNeededMsg />}
            {financingNeeded && !financingAvailable && <NoFinancingOptionsMsg />}

            <div
              className={clsx(
                'form-input',
                // input should be still present in DOM to be able to submit form
                // but hidden if there is no financing options or only one option (agree with the seller terms)
                (!financingNeeded || !financingAvailable) && 'visually-hidden',
              )}
            >
              <label>YOUR FINANCING HANDLER *</label>

              {creditOptions.map((item: any) => (
                <PriceOption
                  priceItem={item}
                  caption={getCreditOptionCaption(item)}
                  key={item.id}
                  name={FINANCING_FIELDS.credit}
                  currency={currency}
                />
              ))}

            </div>
          </div>
        </>
      )}
      {/* Regular offer financing (offer / proposal) === END */}

      {/* PROPOSAL agreed on payment terms block */}
      {isShouldHideFinancing && <RFPFinancingVas />}
      {/* PROPOSAL agreed on payment terms block === END */}
    </>
  );
};

export default OfferFinancing;
