import React, { FC } from 'react';
import MailLink from 'SHARED/components/MailLink';

const NoLogisticsOptionsMsg: FC = () => (
  <div className="attention-message no-margin">
    <p>
      Currently none of our partners can offer logistic options for this trade. Please change your incoterms or <MailLink text="contact us" /> if you need any assistance.
    </p>

    <p>
      If you continue you will have to accept the seller&#8217;s incoterms terms for this offer.
    </p>
  </div>
);

export default NoLogisticsOptionsMsg;
