import React, { useState } from 'react';
import RemoveBidModal from 'modals/RemoveBidModal';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import CounterOffer from './CounterOffer';
import OriginalOfferSection from './OriginalOfferSection';
import './bid-section.scss';
import LatestBidSection from './LatestBidSection';
import PlaceBidForm from './PlaceBidForm';

interface OfferNegotiationProps {
  validateAcceptOffer: () => void
  validateAcceptCounterBid: (bidId: number) => void
}

const OfferNegotiation: React.FC<OfferNegotiationProps> = ({ validateAcceptOffer, validateAcceptCounterBid }) => {
  const [isRemovePopup, setIsRemovePopup] = useState(false);

  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const {
    id,
    currency,
    isCanAddBid,
    isCanAccept,
    remainingVolume = 0,
    created,
    splittable,
    latestBid,
    personalOffer,
    status,
  } = ON_SPEC;

  const isSimpleOffer = ON_SPEC.type?.value === 'SIMPLE_ALREADY_PRODUCED';

  const handleDeleteBid = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsRemovePopup(true);
  };

  return (
    <>
      {isRemovePopup && <RemoveBidModal handleClose={setIsRemovePopup} type="ON_SPEC" offerId={id} bidId={latestBid?.id} />}
      {/* ***************** original sellers' offer wich may be accepted any time ***************** */}
      {isCanAccept && (
        <OriginalOfferSection
          acceptOffer={validateAcceptOffer}
          volume={remainingVolume}
          createdAt={created}
          splittable={splittable}
        />
      )}

      {/* ***************** latest bid section from our company ***************** */}
      { latestBid
        && !latestBid.autoDeal
        && !latestBid.dealId
        && status === 'ACTIVE' && (
        <LatestBidSection
          bid={latestBid}
          currency={currency}
          onDeleteBid={handleDeleteBid}
          isSimpleOffer={isSimpleOffer}
        />
      )}

      {/* ***************** counter offer from SELLER *****************  */}
      {personalOffer && personalOffer.acceptable && (
        <CounterOffer
          bid={personalOffer}
          currency={currency}
          acceptBid={validateAcceptCounterBid}
        />
      )}

      {/* ***************** add new bid form *****************  */}
      {isCanAddBid && <PlaceBidForm />}
    </>
  );
};

export default OfferNegotiation;
