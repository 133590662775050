import { BidFormFields, PriceParams } from 'SHARED/types/bidTypes';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

// ? example of payload for VAS prices (with details on which fields are required and which are optional)
export interface IVasPricesPayload {
  // required fields
  offerId: number;
  offerVersion: string;

  price: number;
  volume: number;

  isIncludeVas: boolean;
  isAcceptingOffer: boolean;

  // optional fields
  packaging?: string | null;

  buyerIncoterms?: string | null;
  logisticLocationId?: number | null;
  logisticPriceId?: number | null;

  paymentTerm?: number | null;
  creditConditionId?: number | null;
  paymentOffset?: number | null;
}

interface IPrepareVasPayloadParams {
  form: BidFormFields;
  offer: OnSpecOffer;
  isIncludeVas: boolean;
}

const prepareVasPayload = (params: IPrepareVasPayloadParams): PriceParams => {
  const { form, offer, isIncludeVas } = params;
  const { id, version, price, remainingVolume, productContainers } = offer;
  const {
    // logistics
    buyerIncoterms,
    logisticLocationId,
    logisticPriceId,

    // financing
    paymentTerm,
    creditConditionId,
    paymentOffset,

    // packaging can be only for TOBE PRODUCED OFFERS
    packaging,
  } = form;

  const isOwnLogistic = logisticPriceId === 'OWN';
  const isOwnFinancing = creditConditionId === 'OWN';

  return {
    // =================
    // ?=== REQUIRED ===
    offerId: id,
    offerVersion: version,

    // TODO: remove this when TBP is handled at the backend
    // ? these are temporary solutions for TBP offers if remainingVolume is 0(zero)
    // ? guards for this case should be implemented at the backend
    // ? even now this does not solve the issue with logistics prices, financing only
    price: price || 1,
    volume: remainingVolume || productContainers?.[0].volume || 1,

    // include VAS data or not (VasLogistics, VasFinancing)
    isIncludeVas,
    // unknown for now
    isAcceptingOffer: true,

    // =================
    // ?=== OPTIONAL ===
    packaging: packaging?.value || null,

    // FOB, CFR, CIF etc
    buyerIncoterms: buyerIncoterms?.value || null,
    // id of location, PORT or NON_PORT
    logisticLocationId: logisticLocationId?.payload.id || null,
    logisticPriceId: (isOwnLogistic || !logisticPriceId) ? null : logisticPriceId,

    // time_loading etc
    paymentTerm: paymentTerm ? paymentTerm.value : null,
    // 7, 14, 30, 60, 90 etc
    paymentOffset: paymentTerm?.hasDayOffset ? (paymentOffset?.value || 0) : null,
    // id of credit provider (e.g. 1, 2 ... OWN)
    creditConditionId: (isOwnFinancing || !creditConditionId) ? null : creditConditionId,
  };
};

export default prepareVasPayload;
