import { UseFormWatch } from 'react-hook-form';
import { calculateBidNetPrice, calculateFinancing, calculateLogistics, findLogisticsLocation } from 'SHARED/helpers/VAS';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

interface IProps {
  type: 'offer' | 'bid'; // accept offer or place a bid
  volume?: number;
  price?: number;
  watch: UseFormWatch<any>;
}

interface IHookReturn {
  netPrice: number;

  packagingPrice: number;

  logisticPrice: number;
  selectedLogisticsType: string | null;

  creditPrice: number;
  totalPrice: number;
}

function useCalculateOfferPrices(props: IProps): IHookReturn {
  const { type: hookType, volume = 0, price = 0, watch } = props;

  const { ON_SPEC: { priceDetails, vasFinancing, productContainers } } = useTypedSelector((state) => state.offer);

  const logisticsLocationValue = watch('form.logisticLocationId');
  const logisticsPriceValue = watch('form.logisticPriceId');
  const packagingPriceValue = watch('form.packaging');

  const packagingPrice = packagingPriceValue?.params?.price || 0;

  // logistics details === START
  const selectedLogisticsPrice = findLogisticsLocation(logisticsLocationValue, logisticsPriceValue);
  const selectedContainerPrice = (
    selectedLogisticsPrice?.currency === 'USD'
      ? selectedLogisticsPrice?.transportPriceUsd
      : selectedLogisticsPrice?.transportPriceEur
  ) || 0;

  const selectedContainer = productContainers?.find(
    (container) => container.containerCategory === selectedLogisticsPrice?.type?.value,
  );
  const selectedContainerVolume = selectedContainer?.volume || 0;
  // logistics details === END

  // financing details === START
  const financingMatch = vasFinancing?.options?.find(
    (option) => option?.partner?.value === priceDetails?.financingProvider?.value,
  ) || null;
  // financing details === END

  const calculatedLogistics = calculateLogistics({
    unitVolume: selectedContainerVolume,
    unitPrice: selectedContainerPrice,
    volume,
  });

  // if (hookType === 'bid')
  // ? then we need to reverse calculate net price first
  let offerNetPrice = price;

  if (hookType === 'bid') {
    const calculatedNetPrice = calculateBidNetPrice({
      volume,
      grossPrice: price,
      totalLogisticsPrice: calculatedLogistics.total,
      financingProvider: financingMatch,
      packagingPrice: packagingPriceValue?.params?.price || 0,
    });

    offerNetPrice = calculatedNetPrice.perMT;
  }
  // if (hookType === 'bid') === END

  const calculatedCredit = calculateFinancing({
    volume,
    netPrice: offerNetPrice,
    totalLogisticsPrice: calculatedLogistics.total,
    financingProvider: financingMatch,
    packagingPrice: packagingPriceValue?.params?.price || 0,
  });

  const netPrice = offerNetPrice;
  const logisticPrice = calculatedLogistics.perMT || 0;
  const creditPrice = calculatedCredit.perMT || 0;
  const totalPrice = netPrice ? (netPrice + logisticPrice + creditPrice + packagingPrice) : 0;
  // NEW === END

  return {
    netPrice,

    packagingPrice,

    logisticPrice,
    selectedLogisticsType: selectedLogisticsPrice?.type?.value || null,

    creditPrice,
    totalPrice,
  };
}

export default useCalculateOfferPrices;
